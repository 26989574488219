import createDataContext from "./createContext";
import { WebTrakingService } from "../api";
import { sendNotification } from "../hooks/useNotification";
import {  NotificationContent } from "../components";
import { Typography } from "@mui/material";

const INDIRIZZI_CODIFICATI_LISTS = "indirizzi_codificati_lists"
const INDIRIZZO_CODIFICATO = "indirizzo_codificato"
const POP_VAR_POST = "pop_var_post"
const COLUMNS_CUSTOM = "columns_custom";
const RICERCA_INDIRIZZI = "ricerca_indirizzi";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";
const ERROR_MESSAGGE = "error_message";
const MIT_DES_CHOICE = "mit_des_choice"


const INITIAL_STATE = {
  indirizziCodificatiLists: [],
  columnsCustom: [],
  stepper: [],
  indirizziDetail: [],
  postIndirizziVar:[],
  recordCount: 0,
  isValidAdd:null,
  ricercaIndirizzi:[],
  errorMessage:"",
  mitDesChoice:"",

  filter: {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
    DestNote:"",
    spedizioneDaInviare: [],
  },
  resettaFiltri:false,

  testDoc:{
    base64:"data:text/plain;base64,UmFnU29jO0luZGlyaXp6bztjaXZpY287TG9jYWxpdGE7UHJvdmluY2lhO0NhcDtOYXppb25lO0NvZGljZTtQZXJzb25hO1RlbGVmb25vO21haWw7bm90YSBjb25zZWduYTthZ2VudGU7bm90YSBpbnRlcm5hOw0KUGFuZSBlIExhdHRlIHMuci5sLjtWaWEgUm9tYTsxOyBQYWRvdmE7UEQ7MzUwMTA7SVQ7MTIzNDU7TWFyaW8gQmx1OzA0OTk1NTUyNTQ7ZW1haWxAbWFpbC5jb207bm90ZSBkaSBjb25zZWduYTtGcmFuY287bm90YSBpbnRlcm5hOw0KUXVhdHRyb2dhdHRpIHMucC5hO1ZpYSBDb3JzbyBEZWwgUG9wb2xvOzI7IFBhZG92YTtQRDszNTAxMDtJVDtjb2QwMDA2O0FsZXNzYW5kcm8gVmVyZGk7NDkxMjM0NTg3O2VtYWlsMkBtYWlsMi5jb207bm90ZSBkaSBjb25zZWduYTthZ2VudGU0O25vdGEgaW50ZXJuYTsNCk1lbGEgc3JsO1ZpYSBHYXJpYmFsZGk7MzsgUGFkb3ZhO1BEOzM1MDEwMjtJVDs5ODc1NDtGcmFuY2VzY28gQmlhbmNoaTs0OTEyMzQ1Njc4O2VtYWkzbDNAbWFpbDMuY29tO25vdGUgZGkgY29uc2VnbmE7YWdlbnRlMjtub3RhIGludGVybmE7DQpyczAwODtWaWEgUy4gRnJhbmNlc2NvOzQ7IFBhZG92YTtQRDszNTAxMDI7SVQ7NDU3ODk7TWFyY28gUm9zc2k7NDkxMjM0NTY3ODtlbWFpM2wzQG1haWwzLmNvbTtub3RlIGRpIGNvbnNlZ25hO2FnZW50ZTM7bm90YSBpbnRlcm5hOw",
    filename:"ProvaAnagrafiche.csv"  ,
    mimeType:"data:text/plain;base64,"
  },

};


const indirizziCodificatiReducer = (state, action) => {
  switch (action.type) {
    case INDIRIZZI_CODIFICATI_LISTS: {

      const { data, countRecord } = action.payload;
      return { ...state, indirizziCodificatiLists:data, recordCount:countRecord, resettaFiltri:false};
    }
    case INDIRIZZO_CODIFICATO: {

      return { ...state, indirizziDetail: action.payload };
    }
    case COLUMNS_CUSTOM: {

      return { ...state, columnsCustom: action.payload };
    }
    case POP_VAR_POST: {

      return { ...state, postIndirizziVar: action.payload };
    }
    case RICERCA_INDIRIZZI: {

      return { ...state, ricercaIndirizzi: action.payload };
    }
    case MIT_DES_CHOICE: {
      return { ...state, mitDesChoice: action.payload };
    }
    case FILTER: {

      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
       return { ...state, filter: INITIAL_STATE.filter, resettaFiltri:true };
    }
    case ERROR_MESSAGGE: {
      return { ...state, errorMessage: action.payload };
    }
    default: {
      return state;
    }
  }
};

const getIndrizziCodificati = (dispatch) => async (rif, limit, offset, navigate, dataSet, recordCount, data, filterAnagrafiche) => {
  /* {valueSearch?.ragSoc === true && valueSearch?.codice === true  ? "&Codice+RagSoc": valueSearch?.ragSoc === true && valueSearch?.codice === false ? "&RagSoc" : valueSearch?.ragSoc === false && valueSearch?.codice === true ? "&Codice" : "&all"} */
   const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/websearch?limit=${limit}&offset=${offset}&all=${filterAnagrafiche.searchall}`);

  if (response.data) {
    let data = dataSet;
    if (dataSet.length === 0 || rif !== "") {
      dispatch({
        type: INDIRIZZI_CODIFICATI_LISTS,
        payload: {
          data: response.data.data,
          countRecord: response.data.recordcount_total,
        },
      });
    } else if (dataSet.length < recordCount) {
      response.data.data.forEach((element) => {
        data.push(element);
      });
      dispatch({
        type: INDIRIZZI_CODIFICATI_LISTS,
        payload: {
          data: data,
          countRecord: response.data.recordcount_total,
        },
      });
    }
  }
  let col = [
   /*  {
      field: "checkbox",
      headerName: "",
      //flex: 1,
    }, */
    {
      field: "action",
      headerName: "Azioni",
      //flex: 1,
    },
  ];
  response.data.columns.forEach((data) => {
    col = [
      ...col,
      {
        field: data.id,
        headerName: data.label,
        minWidth: data.minWidth,
        flex: 1,
      },
    ];
  });
  dispatch({ type: COLUMNS_CUSTOM, payload: col });
  dispatch({type: MIT_DES_CHOICE, payload : response.data.DefaultMitDesChoice})
};

function notificationMessage(title, message)
{
  return(
    <div>
      <Typography variant="subtitle1" gutterBottom>
        {title}
      </Typography>
      <Typography variant="caption" gutterBottom>
        {message}
      </Typography>
    </div>
  )
}

const postFile = (dispatch) => async ( fileBase64, filename) => {
  const response = await WebTrakingService.post(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/uploadfile`, 
  JSON.stringify({imagebase64:fileBase64, filename:filename})); 
   if(response){

    sendNotification(
      // <NotificationContent service={"File caricato con successo!"} />,
      "",
      notificationMessage("File caricato con successo!", response.data.Message),
      "success",
      5
    );
    getIndrizziCodificati(dispatch)("", 100, 0, false, [], 0, false, {
      searchall: "",
    })
    }
    else {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5,
      );
    }
}

const getIndrizziCodificato = (dispatch) => async (idIndCod) => {
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/${idIndCod}`);
  if(response){
    dispatch({ type: INDIRIZZO_CODIFICATO, payload: response.data.data });
  }
}

const getSearchIndirizziEsistenti = (dispatch)=> async(testoRicerca, popola)=>{
    const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/websearch?Codice+RagSoc=${testoRicerca}`);
    if(response){
      
      dispatch({ type: RICERCA_INDIRIZZI, payload: response.data.data });
    }else{
      dispatch({ type: RICERCA_INDIRIZZI, payload: [] });
    }
}

const putIndrizziCodificato = (dispatch) => async (idIndCod, formAggiornato, navigate) => {
  if(formAggiornato.Localita.split("-")[1]){
    formAggiornato.Localita = formAggiornato.Localita.split("-")[1]
  }
  const response = await WebTrakingService.put(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati/${idIndCod}`, JSON.stringify(formAggiornato));
  if(response){
    dispatch({ type: INDIRIZZO_CODIFICATO, payload: response.data.data });
    // sendNotification(
    //   <NotificationContent service={"OK!"} />,
    //   "Anagrafica modificata con successo!",
    //   "success",
    //   5
    // );
    // const delay = ms => new Promise(res => setTimeout(res, ms));
    // await delay(5000);
    getIndrizziCodificati(dispatch)("", 100, 0, false, [], 0, false, {
      searchall: "",
    })
    navigate("/indirizzi-codificati")
  }
}

const postIndrizziCodificato = (dispatch) => async (formAggiornato, handleCloseDialog) => {
  if(formAggiornato.Localita.split("-")[1]){
    formAggiornato.Localita = formAggiornato.Localita.split("-")[1]
  }
  const response = await WebTrakingService.post(`${JSON.parse(localStorage.getItem("configurazioni")).server}/indirizzicodificati`, 
  JSON.stringify(formAggiornato));
  if(response){
    getIndrizziCodificati(dispatch)("", 100, 0, false, [], 0, false,{
      searchall: "",
      DataIniziale: "",
      DataFinale: "",
      Riferimento: "",
      DestProvincia: "",
      DestLocalita: "",
      DestRagSoc: "",
      DestNazione: "",
      StatoWeb: "",
      spedizioneDaInviare: [],
    },)
    handleCloseDialog()
  }
}

const deleteIndirizzoCodificato = (dispatch) => async(PrgIndirizzo) => {
  const response = await WebTrakingService.delete(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
    }/indirizzicodificati/${PrgIndirizzo}`
  );
  if(response){
    if (response.data.Success) {
      dispatch({ type: ERROR_MESSAGGE, payload: "" });
      sendNotification(
        <NotificationContent service={"Anagrafica eliminata!"} />,
        "Anagrafica eliminata con successo",
        "success",
        5
      );
      getIndrizziCodificati(dispatch)("", 100, 0, false, [], 0, false,{
        searchall: "",
        DataIniziale: "",
        DataFinale: "",
        Riferimento: "",
        DestProvincia: "",
        DestLocalita: "",
        DestRagSoc: "",
        DestNazione: "",
        StatoWeb: "",
        spedizioneDaInviare: [],
      },)
    }
    else
    {
      sendNotification(
        <NotificationContent service={"Errore!"} />,
        response.data.Err_Message,
        "error",
        5
      );
      dispatch({ type: ERROR_MESSAGGE, payload: response.data.Err_Message });
    }
  }
};

const aggiornaForm = (dispatch) => async (fromInAggiornamento) => {
  dispatch({ type: POP_VAR_POST, payload: fromInAggiornamento });
}

const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getIndrizziCodificati(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
  })
};

const clearRicercaIndirizzi = (dispatch)=> async()=>{
    dispatch({ type: RICERCA_INDIRIZZI, payload: [] });
}

export const { Provider, Context } = createDataContext(
indirizziCodificatiReducer,
  {
    getIndrizziCodificati,
    postIndrizziCodificato,
    getIndrizziCodificato,
    putIndrizziCodificato,
    aggiornaForm,
    getSearchIndirizziEsistenti,
    aggiornaFiltri,
    resetFiltri,
    postFile,
    deleteIndirizzoCodificato,
    clearRicercaIndirizzi
  }, // actions
  INITIAL_STATE // initial state
);
