import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";


import { Card, 
  CardContent,
  Grow, 
  IconButton, 
  Stack,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle
 } from "@mui/material";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { AuthContext,  ThemeContext, TrackingContext } from "../context";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useForm } from "../hooks";
import { isRequired } from "../hooks/useForm";
import { useState } from "react";
import { titlePage } from "../utilities";
import { LoginTwoTone, Search } from "@mui/icons-material";
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ConfigClient } from "../utilities"; 
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Powered  By Incontra © "}
      {moment().format("YYYY")}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const { t, i18n } = useTranslation('translation');
  const [LogoSoc, setLogoSoc] = useState("");
  const [configUser, setConfigUser] = useState({});
  const [logoTruckAnim, setLogoTruckAnim] = useState("");
  const [loopTest, setLoop] = useState(1);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);

  let logoTruck = '';

 /*  useEffect(() => {
    setTimeout(() => {
      setLoop(() => {
        if (loopTest === 1) {
          return -1;
        }
        return 1;
      });
    }, 5900);
  }, [loopTest]); */

  let navigate = useNavigate();

  const {
    state: { theme },
    darkMode,
  } = React.useContext(ThemeContext);

  const { 
    state:{
      titoloPrivacyDialog,
      corpoPrivacyDialog
    },
    login,
    loginforredirect,
    postAccettaPrivacy,
    logout
   } = React.useContext(AuthContext);

  const { getInfoTrackingSearch } = React.useContext(TrackingContext);

  const validations = [
    ({ username }) =>
      isRequired(username) || { username: "*Username obbligatoria" },
    ({ password }) =>
      isRequired(password) || { password: "*Password obbligatoria" },
  ];

  const playerRef = useRef();

  const {
    values: loginForm,
    changeHandler,
    touched,
    errors,
    isValid,
  } = useForm(
    {
      username: "",
      password: "",
      searchTrack: "",
    },
    validations
  );

  const Change = () => {
    darkMode(!theme);
    if (theme) {
      localStorage.setItem("dark", false);
    } else {
      localStorage.setItem("dark", true);
    }
  };

  function webAppToRefresh(m) {
    var storedDateString = sessionStorage.getItem('refreshDate');
    var currentDate = new Date();


    if (!storedDateString ) {
        return true; 
    }    

    var storedDate = new Date(storedDateString);
    var timeDifferenceInMinutes = Math.floor((currentDate - storedDate) / (1000 * 60));
    var toRefresh = timeDifferenceInMinutes >= 0 && timeDifferenceInMinutes > m

    return toRefresh;
}

  useEffect(() => {
    //29/03/2024 Greg: effettuato per forzare ctrl+F5 e avere sempre client aggiornato
    let toRefresh = webAppToRefresh(20);
    if (toRefresh){
      var currentDate = new Date();
      sessionStorage.setItem('refreshDate', currentDate.toISOString());
      window.location.reload(true);    
     
    }
    
    //24/10/2023 Greg: Se manca user o configurazioni, svuoto localstorage, altrimenti continua a 
    //                 effettuare redirect a dashboard, che in mancanza dei valori fa redirect a 
    //                 login innescando un ciclo senza fine.
    if(localStorage.getItem("user") === null || localStorage.getItem("configurazioni") === null){
      localStorage.clear()
    }

    let token = localStorage.getItem("token");



    if (token) {
      //23/04/2024 Greg: Segnalato da Eleonora Milanese POLETTO:
      //                 
      let pages = ConfigClient()
      let firstActivePage =''
      pages.map((row, i) => {
        if ( i === 0 ){
          firstActivePage = row.route;
        }
      });
            
      navigate(firstActivePage);
    }
  // }, []);

  // useEffect(() => {
    
    const fetchTheme = async (params) => {
      
      let splitPrm = params.split("/");
      let parametro = splitPrm[2];
      let response ={};
      if (parametro === "localhost:3000"){
        response = await fetch(
          `https://configuration-irs.incontraweb.it/?domain=${parametro}`
        )
      }
      else{
        response = await fetch(
          `${splitPrm[0]}//${splitPrm[2]}/config`
        );
      }
      const data = await response.json();
      localStorage.setItem("titlelong", data.titlelong);
      localStorage.setItem("titleshort", data.titleshort);
      if(data.logoFile512){
        setLogoSoc(params+data.logoFile512);
      }else{
        setLogoSoc([]);
      }

      if(data.LoginTruckCustomImage){
        setLogoTruckAnim(params  +data.LoginTruckCustomImage);
        logoTruck = params +data.LoginTruckCustomImage
        
      }
      else{
        setLogoTruckAnim("")
      }

      titlePage(data.titleshort, "Login");
      setConfigUser(data);
    };

    fetchTheme(window.location.href);

    
  }, []);

  const PressKey = (e) => {
    if (e === 13 && isValid) {
      handleLogin();
    }
  };

  const PressKeySearch = (e) => {
    if (e === 13) {
      navigate(`/public/view/tracking/${loginForm.searchTrack}`)
    }
  };

  const handleLogin = () => {
    if(configUser.multiple_app_mode !== undefined && configUser.multiple_app_mode === true){
      loginforredirect(loginForm, navigate, configUser.server,configUser.server2, configUser)
    }
    else{
        login(loginForm, navigate, configUser.server, configUser, setPrivacyDialogOpen);
    }
  };



  //Personalizzazione per barth. richiesta di accettazione privacy policy
  const handleAccettazionePrivacy = () => {
    console.log("--- Da implementare: handleAccettazionePrivacy");
    //TODO: POst per confermare accettazione
    //N.B.: le seguenti posso spostarle direttamente nella funzione che effettua il post
    postAccettaPrivacy(navigate, setPrivacyDialogOpen)

  };



  return (
    <div>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          container
          xs={12}
          sm={6}
          md={6}
          lg={6}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ bgcolor: theme ? "background.paper" : "primary.light" }}
        >
          <Card
            sx={{
              maxWidth: logoTruckAnim?'none':300,
              maxHeight: logoTruckAnim?'none':300,
              borderRadius: 12,
              borderWidth: "0.1rem",
              borderColor: theme ? "secondary.light" : "secondary.light",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            variant="outlined"
          >
            <CardContent>
              {logoTruckAnim? (

                <img
                  src={
                    logoTruckAnim
                  }
                  alt="" //Per sopprimere il warning: img elements must have an alt prop, either with meaningful text, or an empty string for decorative images
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
                
              ):(
                <Player
                  src={require(theme
                    ? "../static/truckPallette.json"
                    : "../static/secondAnimationTruck.json")}
                  autoplay
                  style={{ height: 250, width: 250 }}
                  ref={playerRef}
                  onEvent={(event) => {
                    if (event === "loop") {
                      playerRef.current.setPlayerDirection(loopTest);
                    }
                  }}
                  loop
                />
                )
              }
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Stack spacing={2} m={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              <IconButton sx={{ ml: 1 }} onClick={() => Change()} color="inherit">
                {theme ? <Brightness4Icon /> : <Brightness7Icon />}
              </IconButton>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
                m: 1,
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              <Grow
              in={LogoSoc.length > 0}
              style={{ transformOrigin: "0 0 0" }}
              {...(LogoSoc.length > 0
                ? { timeout: 1000 }
                : {})}
            >
              <img
                src={
                  LogoSoc.length > 0
                    ? LogoSoc
                    : require("../static/logo_true.png")
                }
                alt={"..."}
                style={{ width: 200, height: 200 }}
              />
            </Grow>
            </Box>
            <TextField
              margin="normal"
              fullWidth
              label="Utente"
              autoComplete="off"
              autoFocus
              value={loginForm.username || ""}
              helperText={touched.username && errors.username}
              error={Boolean(touched.username && errors.username)}
              onChange={(e) => changeHandler("username", e.target.value)}
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="off"
              value={loginForm.password || ""}
              onKeyPress={(e) => PressKey(e.charCode)}
              helperText={touched.password && errors.password}
              error={Boolean(touched.password && errors.password)}
              onChange={(e) => changeHandler("password", e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              disabled={!isValid}
              variant="contained"
              onClick={() =>{
                handleLogin()}
              }
              endIcon={<LoginTwoTone />}
            >
              Entra
            </Button>
            {configUser?.publicSearch ? (
              <>
                <TextField
                  required
                  fullWidth
                  id="spedizione"
                  label="Cerca per spedizione"
                  name="spedizione"
                  autoComplete="off"
                  autoFocus
                  onKeyPress={(e) => PressKeySearch(e.charCode)}
                  value={loginForm.searchTrack || ""}
                  onChange={(e) => changeHandler("searchTrack", e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={() =>
                    //navigate(`/public/view/tracking/${loginForm.searchTrack}`)
                    getInfoTrackingSearch(loginForm.searchTrack, navigate)
                  }
                  endIcon={<Search />}
                  disabled={loginForm.searchTrack.length <= 0}
                >
                  Cerca
                </Button>
              </>          

            ) : (
              <></>
            )}

            {
              configUser?.tornaAlSito ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="text"
                  onClick={() =>
                    window.location.href = configUser.tornaAlSito
                  }
                  endIcon={<ExitToAppIcon/>}
                >
                  Torna al sito
                </Button>
              ):(<></>)
            }

            <Copyright />
          </Stack>
        </Grid>
      </Grid>
 
      <Dialog
        open={privacyDialogOpen}
        //TransitionComponent={Transition}
        keepMounted
        onClose={() => {setPrivacyDialogOpen(false)}}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{titoloPrivacyDialog}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <ReactMarkdown
                children={ corpoPrivacyDialog}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
           <Button 
            onClick={() => {setPrivacyDialogOpen(false); logout(navigate)}}
          >
            Rifiuto
          </Button>
          <Button 
            onClick={handleAccettazionePrivacy}
          >
            Accetto
          </Button> 
        </DialogActions>
      </Dialog> 

    </div>
  );
}
