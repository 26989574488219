import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Grid } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CorrispondenteDialog({ isOpen, setIsOpen, data }) {

    const columns = [
        { field: 'PrefissoNumeroPratica', headerName: 'N° Pratica', width: 150 },
        {
            field: 'Mittente', headerName: 'Mittente', flex: 1, renderCell: (params) => {
                return (
                    <Typography>{params.row.Mittente.replace("<br>", ' ').replace("<br>", ' ')}</Typography>
                )
            }
        },
        {
            field: 'Destiatario', headerName: 'Destinatario', flex: 1, renderCell: (params) => {
                return (
                    <Typography>{params.row.Destinatario.replace("<br>", ' ').replace("<br>", ' ')}</Typography>
                )
            }
        },
        { field: '', headerName: 'Data consegna', flex: 1 },
    ];

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <Dialog
                fullScreen
                open={isOpen}
                //onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Conferma consegna
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                        <DataGrid
                            autoHeight
                            rows={data}
                            columns={columns}
                            getRowId={(row) => row.PrgInterno}
                        />
                    </Grid>
                </Grid>
            </Dialog>
        </div>
    );
}