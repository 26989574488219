
import { TravelExploreTwoTone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useForm } from "../../../hooks";
import ClassIcon from '@mui/icons-material/Class';

const Resa = ({ mainForm, setMainForm, vociResa}) => {

  const { values: resaForm, changeHandler } = useForm(
    {
      Resa: mainForm.ResaPerWeb,
    },
    []
  );

  useEffect(() => {
    setMainForm({
      ...mainForm,
      ResaPerWeb: resaForm.Resa,      
    });
  }, [resaForm]);


  return (
    <Card
      elevation={0}
      sx={{ 
        background: (theme) => theme.palette.warning.main + "20"
      }}
    >
      <CardHeader
        title={"Resa"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<ClassIcon />}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth >
              <InputLabel 
                id="demo-simple-select-label"
                style={{ display: 'flex', alignItems: 'center' }}
                size='small'
              >
                Resa
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                size="small"
                value={resaForm.Resa || ""}
                label="Resa"
                onChange={(e) =>
                  changeHandler("Resa", e.target.value)
                }
              >
                <MenuItem value={""}>Scegli la resa</MenuItem>
                { vociResa ? (
                vociResa.map((resa, i) => (
                  <MenuItem key={i} value={resa.code}>
                    {resa.label}
                  </MenuItem>
                ))):(<></>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Resa;
