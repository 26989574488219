import * as React from "react";
import { 
  Grid, 
  LinearProgress, 
  Stack, 
  Typography,
  Button,
  ButtonGroup,
} from "@mui/material";
import {
  LocalShipping,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { TrackingContext } from "../context";
import { useEffect } from "react";
import { CorrispondenteDialog, FilterBar } from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const limit = 100;
const offset = 0;

const listaStato =[
  { value:"CON", text:"Consegnato"},
  { value:"INC", text:" In consegna"},
  { value:"CON", text:"Disitinta"},
  { value:"ALTRI", text:"In lavorazione"},
]


const Corrispondente = () => {
  const { t } = useTranslation("common",{ keyPrefix: 'Corrispondente' })
  const [pageResetVar, setPageResetVar] = useState(false);
  const [checkBoxSelection, setCheckBoxSelection] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const {
    state: { trackingList, columnsCustom, recordCount, filter, resettaFiltri, azioni},
    getCorrispondenteList,
    aggiornaFiltri,
    resetFiltri,
    trackEmail,
    postConsegnato,
    
  } = useContext(TrackingContext);

  const [reload, setReload] = useState(false);



  useEffect(() => {
    titlePage("", t("titlePage"));
    getCorrispondenteList(
      "",
      limit,
      offset,
      navigate,
      [],
      recordCount,
      false,
      filter
    );
  }, []);

  const emailActionTrak=(e)=>{
    trackEmail(e)
   }

  return (
    <Grid container spacing={2}>
      <Grid container item mr={2} spacing={2} direction={"row"}>
        <Grid item xs={12} md={12} lg={12}>
          <FilterBar
            type={true}
            recordCount={recordCount}
            filter={filter}
            aggiornaFiltri={aggiornaFiltri}
            getMethod={getCorrispondenteList}
            resetFiltri={resetFiltri}
            setViewStatoWeb={true}
            setViewAdvFilter={true}
            setPageResetVar={setPageResetVar}
            listStatoWeb={listaStato}
          ></FilterBar>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>

      <ButtonGroup size={"large"} orientation={"horizontal"}>
          {/* <Button onClick={()=> navigate("/spedizioni/new")} startIcon={<AddBoxTwoTone />} variant="contained">{t("creaSpedizione")}</Button> */}
          {/* <Button
            variant="text"
            startIcon={<PrintTwoTone />}
            disabled={checkBoxSelection.length < 1}
            onClick={() => setOpen(!open)}
          >
            {t("Stampa")}{" "}
            {checkBoxSelection.length < 1
              ? ""
              : "(" + checkBoxSelection.length + ")"}{" "}
          </Button> */}
          {/* <Dialog onClose={handleClose} open={open}>
            <DialogTitle>{t("SelezionaStampa")}</DialogTitle>
            <List sx={{ pt: 0 }}>
              {configPrint.map((print) => (
                <ListItemButton
                  onClick={() =>
                    handleListItemClick(print.codicedoc, checkBoxSelectionPrint)
                  }
                  key={print.codicedoc}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: red[400] }} size="small">
                      <PrintTwoTone color="inherit" size="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={print.descrizione} />
                </ListItemButton>
              ))}
            </List>
          </Dialog> */}
          <Button
            variant="text"
            startIcon={<LocalShipping />}
            disabled={checkBoxSelection.length < 1}
            onClick={() =>
              setIsOpen(true)
            }
          >
            {t("ConfermaConsegnato")}
          </Button>
          <CorrispondenteDialog isOpen={isOpen} setIsOpen={setIsOpen} data={checkBoxSelection}></CorrispondenteDialog>
        </ButtonGroup>

        {columnsCustom.length > 0 ? (
          <CustomPaginationActionsTable
             setCheckBoxSelectionPrint={setCheckBoxSelection} 
            rows={trackingList}
            columns={columnsCustom}
            limit={100}
            navigate={navigate}
            getMethodForList={getCorrispondenteList}
            recordCount={recordCount}
            routeDetail={"/tracking"}
            idElement={"PrgInterno"}
            pageReset={pageResetVar}
            actionShow={true}
            viewAction={{
              mail: azioni?.email?.visible,
              view: true,
              location: false,
              print: false,
              edit:azioni?.modifica?.visible,
              delete:azioni?.annulla?.visible,
            }}
            /*emailAction={(e)=>emailActionTrak(e)}*/
            filterList={filter}
            resettaFiltri={resettaFiltri}
          />
        ) : (
          <Stack justifyContent={"center"} alignContent="center">
            <Typography>{t("loadPage")}</Typography>
            <LinearProgress color="success" />
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default Corrispondente;
