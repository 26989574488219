import React from "react";
import Mittente from "./AgromentiOrdiniFormEdit/Mittente";
import Destinatario from "./AgromentiOrdiniFormEdit/Destinatario";
import Servizio from "./AgromentiOrdiniFormEdit/Servizio";
import DettaglioMisureMerce from "./AgromentiOrdiniFormEdit/DettaglioMisureMerce";
import TotaleMerce from "./AgromentiOrdiniFormEdit/TotaleMerce";
import NotaDimensioneMerce from "./AgromentiOrdiniFormEdit/NotaDimensioneMerce";
import UploadFile from "./AgromentiOrdiniFormEdit/UploadFile";
import Contrassegno from "./AgromentiOrdiniFormEdit/Contrassegno";
import MerceADR from "./AgromentiOrdiniFormEdit/MerceADR";
import Assicurazione from "./AgromentiOrdiniFormEdit/Assicurazione";

const OrdiniEditFormContainer = ({ mainForm, setMainForm, configOrder }) => {
  return (
    <>
      {configOrder?.mittente?.visible ? (
        <Mittente
          setMainForm={setMainForm}
          mainForm={mainForm}
          oraPronta={configOrder?.orapronta}
          luogoRitiroVisible={
            configOrder?.mittente?.default_LuogoRitiro
          }
          riferimentoVisible={
            configOrder?.mittente?.riferimento_visible
          }
          readOnly={configOrder?.mittente?.readonly}
          indicatoriConfig={configOrder?.mittenteindicatori}
        />
      ) : (
        <></>
      )}
      {configOrder?.destinatario?.visible ? (
        <Destinatario
          setMainForm={setMainForm}
          mainForm={mainForm}
          luogoRitiroVisible={
            configOrder?.destinatario?.default_LuogoRitiro
          }
          riferimentoVisible={
            configOrder?.destinatario?.riferimento_visible
          }
          readOnly={configOrder?.destinatario?.readonly}
          indicatoriConfig={configOrder?.destinatarioindicatori}
        />
      ) : (
        <></>
      )}
      {configOrder?.servizio?.visible ? (
        <Servizio setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder?.dettagliomisuremerce?.visible ? (
        <DettaglioMisureMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder?.totalemerce?.visible ? (
        <TotaleMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder?.notadimensioni?.visible ? (
        <NotaDimensioneMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder?.uploadfile?.visible ? (
        <UploadFile setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder?.contrassegno?.visible ? (
        <Contrassegno setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder?.merceadr?.visible ? (
        <MerceADR setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder?.assicurazione?.visible ? (
        <Assicurazione setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
    </>
  );
};

export default OrdiniEditFormContainer;
