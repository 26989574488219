  import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Stack,
    Box,
    IconButton,
    Tooltip
  } from "@mui/material";

  import {
    Delete,
    DocumentScanner,

  } from "@mui/icons-material";
  import { useState } from "react";
  import React from "react";
//import { FUpload } from ".";
  
  const FUpload = ({ addOrdiniForm, changeHandler }) => {
    //file names buffer
    const [fileName, setFileName] = useState([]);
    //encoded file buffer
    const [base64File, setbase64File] = useState([]);
  
    const convertBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
    const constMultipleBase64 = async (file) => {
      let fileBase64 = base64File;
      let fileNameAppoggio = fileName;
      const base64 = await convertBase64(file);
      fileBase64.push(base64);
      fileNameAppoggio.push(file.name);
      setbase64File(fileBase64);
      setFileName(fileNameAppoggio);
      //changeHandler("UploadFile", fileBase64);
    };
    
    const handleFileRead = (event) => {
      let file;
      for (let i = 0; i < event.target.files.length; i++) {
        file = event.target.files[i];
        constMultipleBase64(file);
      }
    };
  
    const annullaUpload = () => {
      setbase64File([]);
      setFileName([]);
      //changeHandler("UploadFile", []);
    };
    return(
      <div>
        <Box sx={{width : 275}}>
          <Card elevation={5} variant="outlined">
            <CardHeader          title="Documenti"
          subheader={
            fileName.length > 0
              ? fileName.map((data, index) => {

                  return ` ${data},  `;
                })
              : "Nessun documento caricato!"
          }
          subheaderTypographyProps={{
            fontWeight: "bold",
          }}
          titleTypographyProps={{
            fontWeight: "bold",
            fontSize:18
          }}
          action={
            <Tooltip title="Cancella i dati caricati">
              <IconButton
                color="error"
                size="large"
                onClick={() => annullaUpload()}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          }
          avatar={<DocumentScanner />}
        />
            <CardContent>
            <input
             id="icon-button-photo"
             onChange={(e) => handleFileRead(e)}
             type="file"
             hidden
           />
            <label htmlFor="icon-button-photo">
               {/* <Tooltip title="carica documento"> */}
              <Stack direction={"row"} spacing={5}>
                 <Button
                 component="span"
                 variant="contained"
                >
                 Carica documento
               </Button>
               

             </Stack>
           </label>
           <Button variant="contained" color="warning" onClick={()=>annullaUpload()}>
                Anulla Caricamento
            </Button>
            </CardContent>
          </Card>
        </Box>
      </div>
    );

  };
  
  export default FUpload;
  