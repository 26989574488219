import * as React from "react";
import { useContext } from "react";
import { DocumentiContext } from "../context";
import { useEffect } from "react";
import {DocumentiGroups, DocumentiDialog } from "../components";
import { titlePage } from "../utilities";
import { useState } from "react";



const Documenti = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState("")

  const {
    state: { groupsList},
    getGroupsList,
  } = useContext(DocumentiContext);


  useEffect(() => {
    titlePage("", "Documenti");
    getGroupsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
        <DocumentiGroups 
          setIsOpen={setIsDialogOpen}
          setSelectedGroup={setSelectedGroup}
          groupsList={groupsList}
        />

        {isDialogOpen===true?
          (<DocumentiDialog 
              isDialogOpen={isDialogOpen} 
              setIsDialogOpen={setIsDialogOpen} 
              selectedGroup={selectedGroup} 
              setSelectedGroup={setSelectedGroup}
            />)
          :(<></>)
        }
    
    </div>
  );
};

export default Documenti;
