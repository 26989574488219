import createDataContext from "./createContext";

const DARK_MODE = "dark_mode";
const LANGUAGES = "languages";


const INITIAL_STATE = {
  theme: null,
  languages:'it'
};

const themeReducer = (state, action) => {
  switch (action.type) {
    case DARK_MODE: {
      return { ...state, theme:action.payload };
    }
    case LANGUAGES: {
      return { ...state, languages:action.payload };
    }
    default: {
      return state;
    }
  }
};


const darkMode = (dispatch) => async (theme) => {
  dispatch({ type: DARK_MODE, payload: theme });
};

const languagesChoose = (dispatch) => async (language, i18n) => {
  if(language !== "null"){
    localStorage.setItem("languages", language)
    dispatch({ type: LANGUAGES, payload: language });
    i18n.changeLanguage(language);
  }else{
    localStorage.setItem("languages", 'it')
    i18n.changeLanguage('it');
    dispatch({ type: LANGUAGES, payload: 'it' });
  }
};

export const { Provider, Context } = createDataContext(
  themeReducer,
  {
    darkMode,
    languagesChoose
    /* login,
    logout, */
  }, // actions
  INITIAL_STATE // initial state
);
