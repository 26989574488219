import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Grow,
  Skeleton,
  Slide,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import { Add, DocumentScannerTwoTone, DownloadDone } from "@mui/icons-material";
import { DashboardContext } from "../../context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

export const DocumentiDash = ({ lastDoc, totDoc }) => {
  const {
    state: { loadDocumentiDash },
  } = useContext(DashboardContext);

  let navigate = useNavigate();

  return (
    <Card sx={{ height: "100%" }} elevation={loadDocumentiDash ? 3 : 1}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          {loadDocumentiDash ? (
            <Grow
              in={loadDocumentiDash}
              timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
            >
              <Grid item>
                <Typography
                  color="textSecondary"
                  gutterBottom
                  variant="overline"
                >
                  Documenti{/*  {mesecorrente.Month} */}
                </Typography>
                <Typography color="textPrimary" variant="h4">
                  n° {totDoc?.Value ? totDoc.Value : 0}
                </Typography>
              </Grid>
            </Grow>
          ) : (
            <Grid item xs={6}>
              <Skeleton animation="wave" height={20} width="100%" />
              <Skeleton animation="wave" height={40} width="50%" />
            </Grid>
          )}

          <Grid item>
            {loadDocumentiDash ? (
              <Zoom
                in={loadDocumentiDash}
                timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
              >
                <Avatar
                  sx={{
                    backgroundColor: "error.main",
                    height: 56,
                    width: 56,
                  }}
                >
                  <DocumentScannerTwoTone />
                </Avatar>
              </Zoom>
            ) : (
              <Skeleton
                animation="wave"
                variant="circular"
                width={56}
                height={56}
              />
            )}
          </Grid>
        </Grid>
        {loadDocumentiDash ? (
          <>
            {lastDoc?.data.length > 0 ? (
              <Slide
                in={loadDocumentiDash}
                timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
                direction="up"
              >
                <Box
                  sx={{
                    pt: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Add color="success" />
                  <Typography
                    //color="primary"
                    sx={{
                      mr: 1,
                    }}
                    variant="body2"
                  >
                    Agg. di recente:
                  </Typography>
                  <Tooltip
                    title={`
          Ragione Sociale: ${lastDoc?.data.RagSoc} - 
          Nome file: ${lastDoc?.data.NomeFile}
          `}
                  >
                    <Button
                      variant="text"
                      onClick={() =>
                        navigate(`/documenti/${lastDoc?.data.IDDocumento}`)
                      }
                      endIcon={<DownloadDone />}
                    >
                      <Typography variant="caption">
                        {"Doc. n°"}
                        {lastDoc?.data.IDDocumento}
                        {" - "}
                        {lastDoc?.data.RagSoc}
                        {" - "} {lastDoc?.data.CrtData}
                      </Typography>
                    </Button>
                  </Tooltip>
                </Box>
              </Slide>
            ) : (
              <></>
            )}
          </>
        ) : (
          <Box
            sx={{
              pt: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={"90%"}
              height={12}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
