import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import {
  Button,
  Grid,
  TextField,
} from "@mui/material";
import {
  ArrowBack,
  DoneAll,
  NextPlan,
} from "@mui/icons-material";
import { StepperContentCustom } from "../components";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { titlePage } from "../utilities";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
    4: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ["Step1", "Step2", "Step3", "Step4"];

export default function SpeditionStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  let navigate =useNavigate()
  useEffect(() => {
    titlePage("", "Preventivo")
  }, [])

  return (
    <>
      <Stack spacing={2}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label, i) => (
            
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <Stack direction={"row"} spacing={2} justifyContent={"space-around"}>
        {activeStep === steps.length - 1 || activeStep === 0 ? (
          <Button
            disabled
            onClick={() =>
              activeStep === steps.length - 1
                ? setActiveStep(0)
                : setActiveStep(activeStep - 1)
            }
          >
            {""}
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={<ArrowBack />}
            onClick={() =>
              activeStep === steps.length - 1
                ? setActiveStep(0)
                : setActiveStep(activeStep - 1)
            }
          >
            {"Back"}
          </Button>
        )}

        <Button
          endIcon={activeStep === steps.length - 1 ? <DoneAll /> : <NextPlan />}
          variant={"contained"}
          onClick={() =>
            activeStep === steps.length - 1
              ? navigate("/preventivo/dettaglio")
              : setActiveStep(activeStep + 1)
          }
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Stack>
      <Grid container spacing={2} mt={2}>
        <StepperContentCustom>
          {activeStep === 0 ? (
            <>
              <Grid item xs={12} md={4} lg={4} ml={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} mr={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          {activeStep === 1 ? (
            <>
              <Grid item xs={12} md={4} lg={4} ml={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} mr={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          {activeStep === 2 ? (
            <>
              <Grid item xs={12} md={4} lg={4} ml={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} mr={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={3} lg={3} mr={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} ml={2} mr={2}>
                <TextField
                  id="outlined-basic"
                  label="Outlined"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </StepperContentCustom>
      </Grid>
    </>
  );
}
