import { TravelExploreTwoTone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { OrdiniRitiroContext } from "../../../context";
import { useForm } from "../../../hooks";

const Servizi = ({ mainForm, setMainForm, servizioDefault, servizioAutomatico}) => {
  const {
    state: { servizi,  },
    getServizibyRitiri,
  } = useContext(OrdiniRitiroContext);


  const { values: servizioPerWebForm, changeHandler } = useForm(
    {
      ServizioPerWeb: servizioAutomatico || servizioDefault,
    },
    []
  );


  useEffect(() => {
    getServizibyRitiri();

    if( servizioAutomatico !== "" ){
      let found = false;
      servizi.forEach((element) => {
        if (element.codice === servizioPerWebForm.ServizioPerWeb){
          found = true;
        }
      });

      if(found === false){
        if (servizioDefault !== ""){
          changeHandler("ServizioPerWeb", servizioDefault);
        }
        else{
          changeHandler("ServizioPerWeb", "");
        }
      }
    }

  }, []);


  useEffect(() => {

    setMainForm({
      ...mainForm,
      ServizioPerWeb: servizioPerWebForm.ServizioPerWeb,
      
    });


  }, [servizioPerWebForm]);
  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.error.light + "25" }}
    >
      <CardHeader
        title={"Servizio"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<TravelExploreTwoTone />}
      />
      <CardContent>
        <Grid item container spacing={1}>
          <Grid item xs={12} md={12} lg={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Servizio</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                fullWidth
                size="small"
                value={servizioPerWebForm.ServizioPerWeb || ""}
                label="Servizio"
                onChange={(e) =>
                  changeHandler("ServizioPerWeb", e.target.value)
                }
              >
                <MenuItem value={" "}>Scegli il servizio</MenuItem>
                {servizi? (
                    servizi.map((servizio, i) => (
                    <MenuItem key={i} value={servizio.codice}>
                      {servizio.descrizione}
                    </MenuItem>
                ))):(<></>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Servizi;
