import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector  from 'i18next-browser-languagedetector';
import backend from "i18next-http-backend";
import common_en from "./translations/en/common.json";
import common_it from "./translations/it/common.json";
import common_tr from "./translations/tr/common.json";
import common_es from "./translations/es/common.json";
import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";
import { i18nextPlugin } from 'translation-check'
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const Languages = ['en', 'it','tr', 'de', 'es', 'fr']

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(detector)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(i18nextPlugin).init({
    fallbackLng: 'it',
    debug: false,
    lng:localStorage.getItem("languages"),
    saveMissing: true ,
    whitelist:Languages,
    resources:{
      en: {
        common:common_en
      },
      it: {
        common:common_it
      },
      tr: {
        common:common_tr
      },
      fr: {
        common:common_fr
      },
      es: {
        common:common_es
      },
      de: {
        common:common_de
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });


export default i18n;