
  import {
    IconButton,
    Stack,
  } from "@mui/material";
  import React from "react";
  import { v4 as uuidv4 } from "uuid";


  const PackingListButton = ({
    PackingList
  }) => {
    const onPackingLinkClick =(url)=>{
        window.open(
            url,
            "_blank",
            "noopener,noreferrer"
        );
    }
  
    return (
        <Stack direction={"row"}>          
            {PackingList.map((e) => {
                let uuid = uuidv4();
                return(
                    <IconButton
                        key={uuid}
                        onClick={() =>{onPackingLinkClick(e)}}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/pdf-icon.png`}
                            alt="custom icon"
                            style={{ width: 24, height: 24 }}
                        />
                    </IconButton>
                )
            })}
        </Stack>
    );
  };
  
  export default PackingListButton;
  