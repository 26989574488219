import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { AuthContext, OrdiniRitiroContext, TrackingContext } from "../context";
import { useContext } from "react";
import { Alert, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogFullScreen(props) {
  const { child, title, startIcon, actionMethod, subTitle, showButton, handleOpenToOther, handleOpenToOtherFunc, handleCloseToOther, handleCloseToOtherFunc } = props;
  const {
    state: { isValidAdd },
  } = useContext(AuthContext);

  const {
    state: { errorMessage },
    errorCancel,
  } = useContext(OrdiniRitiroContext);

  const {
    state: { errorMessage2 },
    errorCancel2,
  } = useContext(TrackingContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
    errorCancel();
    errorCancel2();
    handleOpenToOtherFunc(false)
    handleCloseToOtherFunc(false)
  };

 useEffect(() => {
  if(handleOpenToOther){
    setOpen(true)
  }
 }, [handleOpenToOther])

 useEffect(() => {
  if(handleCloseToOther){
    setOpen(false)
  }
 }, [handleCloseToOther])
 

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={startIcon}
      >
        {title}
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "fixed" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {subTitle}
            </Typography>

            <Stack direction={"row"} spacing={2}>
              {errorMessage !== "" ? (
                <Alert severity={"error"}>{errorMessage}</Alert>
              ) : (
                <></>
              )}
              {errorMessage2 !== "" ? (
                <Alert severity={"error"}>{errorMessage2}</Alert>
              ) : (
                <></>
              )}
              <Button
                color="warning"
                size={"small"}
                variant={"contained"}
                onClick={handleClose}
              >
                annulla
              </Button>
              <Button
                color="success"
                size={"small"}
                variant={"contained"}
                onClick={() => {
                  actionMethod(handleClose);
                  //handleClose();
                }}
                disabled={!isValidAdd}
              >
                salva
              </Button>
            </Stack>
          </Toolbar>
        </AppBar>
        <Box p={2}>
          {child}
          {showButton && isValidAdd? (
            <Stack direction={"row"} spacing={2} justifyContent="right">
              <Button
                color="warning"
                variant={"contained"}
                onClick={handleClose}
              >
                annulla
              </Button>
              <Button
                color="success"
                variant={"contained"}
                onClick={() => {
                  actionMethod(handleClose);
                  //handleClose();
                }}
                disabled={!isValidAdd}
              >
                salva
              </Button>
            </Stack>
          ) : (
            <></>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
