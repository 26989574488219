import * as React from "react";
import  {useEffect, useState} from "react"
import {
  Button,
  ButtonGroup,
  Grid,
  LinearProgress,
  Stack,
  Typography,

} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { IndirizziCodificatiContext } from "../context";
import { AddBoxTwoTone} from "@mui/icons-material";
import PublishIcon from '@mui/icons-material/Publish';
import {
  AddIndirizziCodificati,
  DialogFullScreen,
  FilterBar,
} from "../components";
import { titlePage } from "../utilities";
import CustomPaginationActionsTable from "../components/CustomPaginationActionsTable";
import { useTranslation } from "react-i18next";
import {UploadDialog, DialogAlertDelete} from "../components"


const limit = 100;
const offset = 0;

const IndirizziCodificati = () => {
  const { t } = useTranslation("common",{ keyPrefix: 'anagrafiche' });
  const [pageResetVar, setPageResetVar] = useState(false)
  const [checkBoxSelectionPrint, setCheckBoxSelectionPrint] = useState([]);
  const [fileUploadDialog, setFileUploadDialog] = useState(false)
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(null);
  const [NPage, setNPage] = useState(0)
  const [ pageOffset, setPageOffset ] = useState(0);

 

  //file names buffer
  const [fileName, setFileName] = useState("");
  //encoded file buffer
  const [base64File, setbase64File] = useState("");

  const handleUploadOpen = () => {
    setFileUploadDialog(true);
  };

  const handleUploadClose = () => {
    setFileUploadDialog(false);
    annullaUpload()
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        //15/05/2023 Greg
        //substring per rimuovere il primo pezzo e otternere solo base64
        //porzione rimossa: "data:text/plain;base64,"
        resolve(fileReader.result.substring(23));
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const deleteAction=(e) =>{
    setOpenAlertDelete(true)
    setIdDelete(e)
  }

  const handleFileRead = (event) => {
    let file;
    for (let i = 0; i < event.target.files.length; i++) {
      file = event.target.files[i];
      constSingleBase64(file);
    }
  };


  const annullaUpload = () => {
    setbase64File("");
    setFileName("");
    //changeHandler("UploadFile", []);
  };



  const constMultipleBase64 = async (file) => {
    let fileBase64 = base64File;
    let fileNameAppoggio = fileName;
    const base64 = await convertBase64(file);
    fileBase64.push(base64);
    fileNameAppoggio.push(file.name);
    setbase64File(fileBase64);
    setFileName(fileNameAppoggio);
    //changeHandler("UploadFile", fileBase64);
  };

  const constSingleBase64 = async (file) => {
    const base64 = await convertBase64(file);
    setbase64File(base64);
    setFileName(file.name);
    //changeHandler("UploadFile", fileBase64);
  };


  const {
    state: {
      indirizziCodificatiLists,
      columnsCustom,
      recordCount,
      postIndirizziVar,
      filter,
      testDoc
    },
    getIndrizziCodificati,
    postIndrizziCodificato,
    aggiornaFiltri,
    resetFiltri,
    postFile,
    deleteIndirizzoCodificato,
  } = useContext(IndirizziCodificatiContext);


  const navigate = useNavigate();
  
  useEffect(() => {
    titlePage("", "Anagrafiche");
    getIndrizziCodificati("", limit, offset, navigate, [], recordCount,false, filter);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid container item mr={2} spacing={2} direction={"row"}>
          <Grid item xs={12} md={12} lg={12}>
            <FilterBar 
            setViewAdvFilter={false}
            type={true}
            recordCount={recordCount}
            filter={filter}
            aggiornaFiltri={aggiornaFiltri}
            getMethod={getIndrizziCodificati}
            resetFiltri={resetFiltri}
            setViewStatoWeb={false}
            setPageResetVar={setPageResetVar}
            pageResetVar={pageResetVar}
            listStatoWeb={[]}
            setCounterApi={setPageOffset}
            setPage={setNPage}
            >
            </FilterBar>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <ButtonGroup size={"large"} mb={2}>
            <DialogFullScreen
              child={<AddIndirizziCodificati />}
              title={t("creaAnagrafiche")}
              showButton={true}
              startIcon={<AddBoxTwoTone />}
              actionMethod={(handleCloseDialog) =>
                postIndrizziCodificato(postIndirizziVar, handleCloseDialog)
              }
            />
            <Button variant="outlined" onClick={handleUploadOpen} startIcon={<PublishIcon></PublishIcon>}>
              Importa dati
            </Button>
          </ButtonGroup>
          {columnsCustom.length > 0 ? (
            <CustomPaginationActionsTable
              setCheckBoxSelectionPrint={setCheckBoxSelectionPrint}
              rows={indirizziCodificatiLists}
              columns={columnsCustom}
              limit={100}
              navigate={navigate}
              getMethodForList={getIndrizziCodificati}
              recordCount={recordCount}
              idElement={"PrgIndirizzo"}
              actionShow={true}
              pageSearchReset={false}
              pageReset={pageResetVar}
              setPageReset={setPageResetVar}
              filterList={filter}
              routeDetail={"/indirizzi-codificati"}
              viewAction={{ mail: false, view: true, location: false, print:false, delete:true }}
              deleteAction={(e) => deleteAction(e)}
              isIndirizziCodificati={true}
              counterApi={pageOffset}
              setCounterApi = {setPageOffset}
              page={NPage}
              setPage = {setNPage}
            />
          ) : (
            <Stack justifyContent={"center"} alignContent="center">
              <Typography>Caricamento...</Typography>
              <LinearProgress color="success" />
            </Stack>
          )}
        </Grid>
        <DialogAlertDelete open={openAlertDelete} setOpen={setOpenAlertDelete} idDelete={idDelete} delFunc={deleteIndirizzoCodificato} setIdDelete={setIdDelete} title={"Anagrafica"}/>
      </Grid>
      <UploadDialog 
        open={fileUploadDialog}
        setOpen={setFileUploadDialog}
        testDoc={testDoc}
        postFile={postFile}
        dialogTitle={"Importa anagrafiche da file"}
      />      


    </div>
  );
};

export default IndirizziCodificati;
