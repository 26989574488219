import createDataContext from "./createContext";
import { WebTrakingService } from "../api";

const DOCUMENT_LIST = "documenti_list";
const COLUMNS_CUSTOM = "columns_custom";
const IMG_BASE64 = "img_base64";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";
const GROUPS_LIST = "groups_list"
const DOCUMENTS_BY_GROUP = "documents_by_group"
const COLUMNS_BY_GROUP = "columns_by_group"

const INITIAL_STATE = {
  documentiList: [],
  columnsCustom: [],
  groupsList:[],
  documentsByGroupList:[],
  columnsByGroup:[],
  imageDoc:{base64:"", fileName:"", mimeType:""},
  countRecord: 0,
  countRecordByGroup:0,
  filter:{ searchall:""}
};

const documentiReducer = (state, action) => {
  switch (action.type) {
    case DOCUMENT_LIST: {
      const { data, countRecord } = action.payload;
      return { ...state, documentiList: data, recordCount: countRecord };
    }
    case COLUMNS_CUSTOM: {
      return { ...state, columnsCustom: action.payload };
    }
    case IMG_BASE64: {
      return { ...state, imageDoc: {base64:action.payload.imagebase64, fileName:action.payload.filename, mimeType:action.payload.mimetype} };
    }
    case FILTER: {
      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
      return { ...state, filter: INITIAL_STATE.filter };
    }
    case GROUPS_LIST: {
      return { ...state, groupsList: action.payload};
    }
    case DOCUMENTS_BY_GROUP: {
      const {docData, count} = action.payload
      return { ...state, documentsByGroupList:docData, columnsByGroup:count};
    }
    case COLUMNS_BY_GROUP: {
      return { ...state, columnsByGroup:action.payload};
    }
    default: {
      return state;
    }
  }
};


const clearGroupData =  (dispatch) => async() =>{
  //clear documents list and record count
  dispatch({
    type: DOCUMENTS_BY_GROUP,
    payload: {
      docData:[],
      count: 0,
    },
  });
  //clear comumns list
  dispatch({ type: COLUMNS_BY_GROUP, payload: [] });
}

const getGroupsList = (dispatch) => 
  async () =>{
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/docemessi/dashboard/groups`
      );
    if(response.data.data){
      dispatch({type: GROUPS_LIST, payload: response.data.data});
    }
    else{
      console.log("ERRORE: dati non restituiti da server\napi: /docemessi/dashboard/groups ")
    }

      
  }

const getDocumentsByGroup = (dispatch) => async (group) =>{
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/docemessi/websearch?gruppoweb=${group}`
      );
    if(response.data.data){
      dispatch({
        type: DOCUMENTS_BY_GROUP,
        payload: {
          docData: response.data.data,
          count: response.data.RecordCount,
        },
      })
      let col = [
         {
           field: "action",
           headerName: "Azioni",
         },
       ];
       response.data.columns.forEach((data) => {
         col = [
           ...col,
           {
             field: data.id,
             headerName: data.label,
             minWidth: data.minWidth,
             //flex: 1,
           },
         ];
       });
       dispatch({ type: COLUMNS_BY_GROUP, payload: col });
      //dispatch({type: COLUMNS_BY_GROUP, payload: response.data.columns});

    }
    else{
      console.log("ERRORE: campo data nella response non presente!!", `\nREQUEST: /docemessi/websearch?gruppoweb=${group}\n`)
    }
}


const getDocumentiList =
  (dispatch) =>
  async (rif, limit, offset, navigate, dataSet, recordCount, reset, filterDocumenti) => {
    const response = await WebTrakingService.get(
      `${
        JSON.parse(localStorage.getItem("configurazioni")).server
      }/docemessi/websearch?limit=${limit}&offset=${offset}&all=${filterDocumenti?.searchall}`
      );
    if (response.data) {
      let data = dataSet;
      if (dataSet.length === 0 || rif !== "") {
        dispatch({
          type: DOCUMENT_LIST,
          payload: {
            data: response.data.data,
            countRecord: response.data.recordcount_total,
          },
        });
      } else if (dataSet.length < recordCount) {
        response.data.data.forEach((element) => {
          data.push(element);
        });
        dispatch({
          type: DOCUMENT_LIST,
          payload: {
            data: data,
            countRecord: response.data.recordcount_total,
          },
        });
      }
    }
    let col = [
     /*  {
        field: "checkbox",
        headerName: "",
        //flex: 1,
      }, */
      {
        field: "action",
        headerName: "Azioni",
        //flex: 1,
      },
    ];
    response.data.columns.forEach((data) => {
      col = [
        ...col,
        {
          field: data.id,
          headerName: data.label,
          minWidth: data.minWidth,
          //flex: 1,
        },
      ];
    });
    dispatch({ type: COLUMNS_CUSTOM, payload: col });
  };

const getDocumentFile = (dispatch) => async (idDocumento) => {
  const response = await WebTrakingService.get(
    `${
      JSON.parse(localStorage.getItem("configurazioni")).server
      }/docemessi/${idDocumento}`
      );
    if(response.data){
      dispatch({ type: IMG_BASE64, payload: response.data});
    }
};

const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getDocumentiList(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
  })
};


export const { Provider, Context } = createDataContext(
  documentiReducer,
  {
    getDocumentiList,
    getDocumentFile,
    aggiornaFiltri,
    resetFiltri,
    getGroupsList,
    getDocumentsByGroup,
    clearGroupData
  }, // actions
  INITIAL_STATE // initial state
);
