import createDataContext from "./createContext";
import { WebTrakingService } from "../api";

const PREVENTIVI_LIST = "preventivi_list"
const PREVENTIVO = "preventivo"
const COLUMNS_CUSTOM = "columns_custom";
const FILTER = "filter";
const FILTER_RESET = "filter_reset";

const INITIAL_STATE = {
  preventiviLists: [],
  columnsCustom: [],
  preventivoDetail: [],
  postPreventiviVar:[],
  recordCount: 0,
  isValidAdd:null,
  ricercaIndirizzi:[],
  filter: {
    searchall: "",
    DataIniziale: "",
    DataFinale: "",
    Riferimento: "",
    DestProvincia: "",
    DestLocalita: "",
    DestRagSoc: "",
    DestNazione: "",
    StatoWeb: "",
    spedizioneDaInviare: [],
  },
  resettaFiltri:false
};


const preventiviReducer = (state, action) => {
  switch (action.type) {
    case PREVENTIVI_LIST: {
      const { data, countRecord } = action.payload;
      return { ...state, preventiviLists:data, recordCount:countRecord, resettaFiltri:false};
    }
    case PREVENTIVO: {
      return { ...state, preventivoDetail: action.payload };
    }
    case COLUMNS_CUSTOM: {
      return { ...state, columnsCustom: action.payload };
    }
    case FILTER: {
      const { key, value } = action.payload;
      return { ...state, filter: { ...state.filter, [key]: value } };
    }
    case FILTER_RESET: {
      return { ...state, filter: INITIAL_STATE.filter, resettaFiltri:true };
    }
    default: {
      return state;
    }
  }
};

const getPreventivi = (dispatch) => async (rif, limit, offset, navigate, dataSet, recordCount, data, filter) => {
    /* ?limit=${limit}&offset=${offset}&all=${filter.searchall} */
  const response = await WebTrakingService.get(`${JSON.parse(localStorage.getItem("configurazioni")).server}/offerte/websearch`);
  if (response.data) {
    let data = dataSet;
    if (dataSet.length === 0 || rif !== "") {
      dispatch({
        type: PREVENTIVI_LIST,
        payload: {
          data: response.data.data,
          countRecord: response.data.recordcount_total,
        },
      });
    } else if (dataSet.length < recordCount) {
      response.data.data.forEach((element) => {
        data.push(element);
      });
      dispatch({
        type: PREVENTIVI_LIST,
        payload: {
          data: data,
          countRecord: response.data.recordcount_total,
        },
      });
    }
  }
  let col = [
   /*  {
      field: "checkbox",
      headerName: "",
      //flex: 1,
    }, */
    {
      field: "action",
      headerName: "Azioni",
      //flex: 1,
    },
  ];
  response.data.columns.forEach((data) => {
    col = [
      ...col,
      {
        field: data.id,
        headerName: data.label,
        minWidth: data.minWidth,
        flex: 1,
      },
    ];
  });
  dispatch({ type: COLUMNS_CUSTOM, payload: col });
}

const aggiornaFiltri = (dispatch) => async (key, value) => {
  dispatch({ type: FILTER, payload: { key: key, value: value } });
};

const resetFiltri = (dispatch) => async () => {
  dispatch({ type: FILTER_RESET });
  getPreventivi(dispatch)("", 100, 0, false, [], 0, false, {
    searchall: "",
  })
};

export const { Provider, Context } = createDataContext(
    preventiviReducer,
  {
    getPreventivi,
    aggiornaFiltri,
    resetFiltri,
  }, // actions
  INITIAL_STATE // initial state
);
