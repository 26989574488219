import * as React from "react";
import Typography from "@mui/material/Typography";
import { AuthContext,  ThemeContext, TrackingContext } from "../context";
import { useNavigate, useParams } from "react-router-dom";
import { getConfig } from "../utilities";
import { useEffect } from "react";
import { useState } from "react";
import { titlePage } from "../utilities";
import moment from "moment";
import { useRef } from "react";
import { useTranslation } from "react-i18next";



export default function LoginRedirected() {
  const [LogoSoc, setLogoSoc] = useState("");
  const [configUser, setConfigUser] = useState({});

  const {username, pwd} = useParams()


  let navigate = useNavigate();

  const {
    state: { theme },
    darkMode,
  } = React.useContext(ThemeContext);

  const { 
    login,    
   } = React.useContext(AuthContext);


  const playerRef = useRef();



  const Change = () => {
    darkMode(!theme);
    if (theme) {
      localStorage.setItem("dark", false);
    } else {
      localStorage.setItem("dark", true);
    }
  };



  useEffect(() => {
    //24/10/2023 Greg: Se manca user o configurazioni, svuoto localstorage, altrimenti continua a 
    //                 effettuare redirect a dashboard, che in mancanza dei valori fa redirect a 
    //                 login innescando un ciclo senza fine.
    if(localStorage.getItem("user") === null || localStorage.getItem("configurazioni") === null){
      localStorage.clear()
    }
    
  }, []);

  useEffect(() => {
    
    const fetchTheme = async (params) => {
      
      let splitPrm = params.split("/");
      let parametro = splitPrm[2];
      let response ={};
      if (parametro === "localhost:3000"){
        response = await fetch(
          `https://configuration-irs.incontraweb.it/?domain=${parametro}`
        )
      }
      else{
        response = await fetch(
          `${splitPrm[0]}//${splitPrm[2]}/config`
        );
      }
      const data = await response.json();
      localStorage.setItem("titlelong", data.titlelong);
      localStorage.setItem("titleshort", data.titleshort);
      if(data.logoFile512){
        setLogoSoc(params+data.logoFile512);
      }else{
        setLogoSoc([]);
      }
      titlePage(data.titleshort, "Login");
      setConfigUser(data);
    };

    fetchTheme(window.location.href);
  
    
    let decodedUser = atob(username);
    let decodedPwd = atob(pwd);
    let config = JSON.parse(localStorage.getItem('configurazioni'));
    if (config === null){
      config = getConfig(window.location.href);
    }

    login({username:decodedUser, password:decodedPwd}, navigate, configUser.server, config);
  }, []);



  return (
    <></>
  );
}
