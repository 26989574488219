import { Grid } from "@mui/material";
import React from "react";
import { SpedizioniMese } from "../components/dashboard/SpedizioniMese";
import { useContext } from "react";
import { DashboardContext } from "../context";
import { useEffect } from "react";
import { OrdiniMese } from "../components/dashboard/OrdiniMese";
import { DocumentiDash } from "../components/dashboard/DocumentiDash";
import { ConfigClient, titlePage } from "../utilities";

const DashBoard = () => {
  const {
    state: {
      spedizioniMeseCorrente,
      spedizioniMesePrecendete,
      ordiniMesePrecendete,
      ordiniMeseCorrente,
      totDoc,
      lastDoc,
      riepilogoSpedGiorno,
      riepilogoOrdGiorno,
    },
    getSpedizioniMese,
    getindirizziCodificatiTotale,
    getOrdiniMese,
    getDocumentiDash,
    getTrackingMese,
    getRiepilogoSpedizioniGiorno,
    getRiepilogoOrdiniGiorno,
  } = useContext(DashboardContext);

  /* const [setMenu, setSetMenu] = useState(ConfigClient()); */

  const setMenu = ConfigClient()
  useEffect(() => {
    titlePage("", "Cruscotto");

    getRiepilogoSpedizioniGiorno();  
    getRiepilogoOrdiniGiorno();
  }, []);

  useEffect(() => {
    setMenu.forEach((element) => {
      switch (element.idMenu) {
        case "tracking":
          //getTrackingMese();
          getSpedizioniMese();
          break;
        case "spedizioni":
          getSpedizioniMese();
          break;
        case "ritiri":
          getOrdiniMese();
          break;
        case "anagrafiche":
          getindirizziCodificatiTotale();
          break;
        case "documenti":
          getDocumentiDash();
          break;
        default:
          break;
      }
    });
  }, [setMenu.length > 0]);


  return (
    <>
      {setMenu.length > 0 ? (
        <Grid container spacing={3}>
          <Grid item container xl={12} lg={12} sm={12} xs={12} spacing={3}>
            {/* {setMenu?.find((res) => res.idMenu === "tracking") ? (
              <Grid item xl={6} lg={6} sm={6} xs={12}>
                <TrackingMese
                  mesecorrente={trackingMeseCorrente}
                  meseprecedente={trackingMesePrecendete}
                />
              </Grid>
            ) : (
              <></>
            )} */}
            {setMenu?.find((res) => res.idMenu === "ritiri") ? (
              <Grid item xl={6} lg={6} sm={6} xs={12}>
                <OrdiniMese
                  mesecorrente={ordiniMeseCorrente}
                  meseprecedente={ordiniMesePrecendete}
                  ordGiorno={riepilogoOrdGiorno}                  
                />
              </Grid>
            ) : (
              <></>
            )}
            {setMenu.find((res) => res.idMenu === "spedizioni" || res.idMenu === "tracking") ? (
              <Grid item xl={6} lg={6} sm={6} xs={12}>
                <SpedizioniMese
                  mesecorrente={spedizioniMeseCorrente}
                  meseprecedente={spedizioniMesePrecendete}
                  spedGiorno={riepilogoSpedGiorno}
                />{" "}
              </Grid>
            ) : (
              <></>
            )}

         {/*    {setMenu.find((res) => res.idMenu === "anagrafiche") ? (
              <Grid item xl={6} lg={6} sm={6} xs={12}>
                <TotalCustomers data={totaleIndirizziCodificati} />
              </Grid>
            ) : (
              <></>
            )} */}
            {setMenu.find((res) => res.idMenu === "documenti") ? (
              <Grid item xl={12} lg={12} sm={12} xs={12}>
                <DocumentiDash lastDoc={lastDoc} totDoc={totDoc} />
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default DashBoard;
