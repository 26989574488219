import React from 'react'

const NotificationContent = ({ service, sound}) => {
    return <>
      {/* <audio autoPlay src={require(sound ==="success" ? "../positive.wav" : "../negative.mp3")} /> */}
        
        {service}
    </>
}

export default NotificationContent