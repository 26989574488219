import { Player } from "@lottiefiles/react-lottie-player";
import { Grid } from "@mui/material";
import React from "react";

const MaintenanceMode = () => {
  return (
    <Grid item container direction={"row"} justifyContent={"center"} spacing={2}>
      <Grid xs={12} md={12} lg={12}>
        <Player
          // src="https://assets2.lottiefiles.com/private_files/lf30_y9czxcb9.json"
          src="cors_json/f30_y9czxcb9.json"
          loop
          autoplay
          speed={2}
          style={{height:"50%", width:"50%"}}
        />
      </Grid>
    </Grid>
  );
};

export default MaintenanceMode;
