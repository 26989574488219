import axios from "axios";
import { errorInterceptor, setLoading } from "./apiUtility";

const instance = axios.create({
  //timeout: 1000,
  headers: {
    'Accept': 'application/json',
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin":'*',
    "X-Powered-By":"InConTra",
    "Access-Control-Allow-Headers":'*',
    "Access-Control-Allow-Methods":'*',
    "Access-Control-Allow-Credentials":'true',
    "Server":"Caddy"
  },
});

instance.interceptors.request.use((request) => {
  setLoading(true);
  return request;
});

instance.interceptors.response.use(
  (response) => {
    setLoading(false);
    return response;
  },
  (error) => {
    //errorInterceptor(error, 'Error');
    setLoading(false);
    return error;
  }
);

export default instance;
