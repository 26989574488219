import * as React from "react";
import { styled } from "@mui/material/styles";
import { Player } from "@lottiefiles/react-lottie-player";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
   "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const CustomNoRowsOverlay = () => {
  return (
    <StyledGridOverlay>
      <Player
        // src="https://assets9.lottiefiles.com/packages/lf20_rc6CDU.json"
        src="cors_json/lf20_rc6CDU.json"
        loop
        autoplay
        speed={2}
        /* style={{ height: "100%", width: "100%" }} */
      />
       <div sx={{ mt: 1 }}>No Rows</div>
    </StyledGridOverlay>
  );
};

export default CustomNoRowsOverlay;
