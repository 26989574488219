import React from "react";
import {
  Destinatario,
  DettaglioMisureMerce,
  Mittente,
  Servizio,
  TotaleMerce,
  NotaDimensioneMerce,
  UploadFile,
  Contrassegno,
  MerceADR,
  Assicurazione,
} from "./AgromentiOrdiniForm";

const Triangrolazione = ({ configOrder, setMainForm, mainForm, scrollOnBott }) => {
  return (
    <>
      {configOrder.ritiri_triangolazione.mittente.visible ? (
        <Mittente
          setMainForm={setMainForm}
          mainForm={mainForm}
          oraPronta={configOrder.ritiri_triangolazione.orapronta}
          luogoRitiroVisible={
            configOrder.ritiri_triangolazione.mittente.default_LuogoRitiro
          }
          riferimentoVisible={
            configOrder.ritiri_triangolazione.mittente.riferimento_visible
          }
          readOnly={configOrder.ritiri_triangolazione.mittente.readonly}
          indicatoriConfig={
            configOrder.ritiri_triangolazione.mittenteindicatori
          }
        />
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.destinatario.visible ? (
        <Destinatario
          setMainForm={setMainForm}
          mainForm={mainForm}
          luogoRitiroVisible={
            configOrder.ritiri_triangolazione.destinatario.default_LuogoRitiro
          }
          riferimentoVisible={
            configOrder.ritiri_triangolazione.destinatario.riferimento_visible
          }
          readOnly={configOrder.ritiri_triangolazione.destinatario.readonly}
          indicatoriConfig={
            configOrder.ritiri_triangolazione.destinatarioindicatori
          }
        />
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.servizio.visible ? (
        <Servizio setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.dettagliomisuremerce.visible ? (
        <DettaglioMisureMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.totalemerce.visible ? (
        <TotaleMerce setMainForm={setMainForm} mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.notadimensioni.visible ? (
        <NotaDimensioneMerce setMainForm={setMainForm}
        mainForm={mainForm} />
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.uploadfile.visible ? (
        <UploadFile  setMainForm={setMainForm}
        mainForm={mainForm}/>
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.contrassegno.visible ? (
        <Contrassegno setMainForm={setMainForm}
        mainForm={mainForm}/>
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.merceadr.visible ? (
        <MerceADR setMainForm={setMainForm}
        mainForm={mainForm}/>
      ) : (
        <></>
      )}
      {configOrder.ritiri_triangolazione.assicurazione.visible ? (
        <Assicurazione setMainForm={setMainForm}
        mainForm={mainForm}/>
      ) : (
        <></>
      )}
    </>
  );
};

export default Triangrolazione;
