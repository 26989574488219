import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import {
  Button,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {HomeTwoTone } from "@mui/icons-material";
import { AuthContext } from "../context";

const ResponsiveAppBar = () => {
  const [LogoSoc, setLogoSoc] = useState([]); 
  const navigate = useNavigate();

  const { state:{configAll} } = React.useContext(AuthContext);


  React.useEffect(() => {
    if (configAll?.logoFile512) {
      setLogoSoc(configAll?.logoFile512);
    }
  }, []);

  const selectPagesIndicator = (loginPage) => {
    navigate(loginPage);
  };

  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Stack
            direction={"row"}
            spacing={5}
            justifyContent={"space-around"}
            sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
          >
            <img
              src={
                LogoSoc.length > 0
                  ? LogoSoc
                  : require("../static/logo_true.png")
              }
              alt={"..."}
              style={{ width: 50, height: 50 }}
            />
             <Button
              color="secondary"
              variant="text"
              disableElevation
              sx={{ fontWeight: "bold", fontSize: 18 }}
            >
              {localStorage.getItem("titleshort")}
            </Button>

            <IconButton
              aria-label="delete"
              onClick={() => selectPagesIndicator("/")}
            >
              <HomeTwoTone color="secondary" />
            </IconButton>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
