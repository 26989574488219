import { Add, Delete, ListTwoTone } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "../../../hooks";

const DettaglioMerce = ({ mainForm, setMainForm, TipoMerceList }) => {
  const [rows, setRows] = useState([
    {
      id: uuidv4(),
      Tipo: "SEL",
      TipoMerce : "SELECT",
      Quantita: 0,
      Peso: 0,
      Lunghezza: 0,
      Larghezza: 0,
      Altezza: 0,
    },
  ]);

  const [totRows, setTotRows] = useState({
    Quantita: 0,
    Peso: "",
    Volume: 0,
  });

  const [lastChoice, setLastChoice] = useState("SEL");
  const [lastTipoMerce, setLastTipoMerce] = useState("SELECT");

  const { values: dettaglioDimensioneMerceForm, changeHandler } = useForm(
    {
      spedmerce: mainForm.spedmerce,
    },
    []
  );

  useEffect(() => {
    if (dettaglioDimensioneMerceForm.spedmerce.length > 0) {
      setRows(dettaglioDimensioneMerceForm.spedmerce);
    }
  }, []);

  useEffect(() => {
    setMainForm({
      ...mainForm,
      spedmerce: dettaglioDimensioneMerceForm.spedmerce,
    });
  }, [dettaglioDimensioneMerceForm]);

  useEffect(() => {
    const QuantitaTot = rows.reduce((acc, obj) => {
      let parser = parseInt(obj.Quantita);
      if (isNaN(acc + parser)) {
        return 0;
      }
      return acc + parser;
    }, 0);
    const PesoTot = rows.reduce((acc, obj) => {
      let parser = parseInt(obj.Peso);
      if (isNaN(acc + parser)) {
        return 0;
      }
      return acc + parser;
    }, 0);

    let VolumeTot = rows.reduce((acc, obj) => {
      let quantita = parseInt(obj.Quantita);
      let larghezza = parseInt(obj.Larghezza);
      let lunghezza = parseInt(obj.Lunghezza);
      let altezza = parseInt(obj.Altezza);
      if (isNaN((quantita * larghezza * lunghezza * altezza) / 1000000)) {
        return 0;
      }
      return acc + (quantita * larghezza * lunghezza * altezza) / 1000000;
    }, 0);

    setTotRows({
      Quantita: QuantitaTot,
      Peso: PesoTot,
      Volume: VolumeTot.toFixed(2),
    });
  }, [rows]);

  const addDettaglioOrdine = () => {
    setRows([
      ...rows,
      {
        id: uuidv4(),
        Tipo: lastChoice,
        Quantita: 0,
        Peso: 0,
        Lunghezza: 0,
        Larghezza: 0,
        Altezza: 0,
      },
    ]);
  };

  const getSizeForTipoMerce = (id) => {
    if(TipoMerceList.visible === true && TipoMerceList.values){
      return(1);
    }
    else{
      return(2)
    }
  };

  const cancellaInserimento = (id) => {
    setRows([...rows.filter((res) => res.id !== id)]);
    changeHandler("spedmerce", [...rows.filter((res) => res.id !== id)]);
  };

  const handelChangeValue = (key, value, index, row) => {
    let arrayData = [...rows.filter((res) => res.PrgMerce !== row.PrgMerce)];
    arrayData.splice(index, 0, { ...rows[index], [key]: value });
    setRows(arrayData);
    changeHandler("spedmerce", arrayData);
  };

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.error.light + "25" }}
    >
      <CardHeader
        title={"Dettaglio merce"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<ListTwoTone />}
      />
      <CardContent>
        {rows.map((res, i) => {
          return (
            <Grid item container spacing={1} key={i}>
              <Grid item xs={12} md={1} lg={1} mr={-5} mt={2}>
                <IconButton
                  onClick={() => cancellaInserimento(res.id)}
                  color="error"
                >
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item xs={12} md={getSizeForTipoMerce()} lg={getSizeForTipoMerce()} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Tipo</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={res.Tipo || ""}
                    onChange={(e) => {
                      if (e.target.value !== 'SEL'){
                        handelChangeValue("Tipo", e.target.value, i, res);
                        setLastChoice(e.target.value);
                      }
                    }}
                    label="Tipo"
                  >
                    <MenuItem value={"SEL"}>Seleziona</MenuItem>
                    <MenuItem value={"C"}>Colli</MenuItem>
                    <MenuItem value={"P"}>Bancali</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {TipoMerceList.visible === true && TipoMerceList.values ? (     
                <Grid item xs={12} md={getSizeForTipoMerce()} lg={getSizeForTipoMerce()} mt={2}>
                  <FormControl fullWidth>
                    <InputLabel id="select-tipo-merce">Tipo merce</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      labelId="select-tipo-merce"
                      id="select-tipo-merce"
                      value={res.TipoMerce || ""}
                      onChange={(e) => {
                        if (e.target.value !== 'SELECT'){
                          handelChangeValue("TipoMerce", e.target.value, i, res);
                          setLastTipoMerce(e.target.value);
                        }
                      }}
                      label="TipoMerce"
                    >
                      <MenuItem value={"SELECT"}>Seleziona</MenuItem>
                      {
                        TipoMerceList.visible === true ? ( 
                          TipoMerceList.values.map((row, i) => {
                            return(
                              <MenuItem value={row.TipoMerce}>{row.TipoMerce}</MenuItem>
                            )  
                          } 
                        )                       
                        ): (<></>)
                      }

                    </Select>
                  </FormControl>
                </Grid>
              ):(<></>)
              }

              <Grid item xs={12} md={1} lg={1}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Quantità"
                  autoComplete="off"
                  value={res.Quantita || ""}
                  onChange={(e) =>
                    handelChangeValue("Quantita", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Peso complessivo (kg)"
                  autoComplete="off"
                  value={res.Peso || ""}
                  onChange={(e) =>
                    handelChangeValue("Peso", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Lunghezza (cm)"
                  autoComplete="off"
                  value={res.Lunghezza || ""}
                  onChange={(e) =>
                    handelChangeValue("Lunghezza", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Larghezza (cm)"
                  autoComplete="off"
                  value={res.Larghezza || ""}
                  onChange={(e) =>
                    handelChangeValue("Larghezza", e.target.value, i, res)
                  }
                />
              </Grid>

              <Grid item xs={12} md={2} lg={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Altezza (cm)"
                  autoComplete="off"
                  value={res.Altezza || ""}
                  onChange={(e) =>
                    handelChangeValue("Altezza", e.target.value, i, res)
                  }
                />
              </Grid>
            </Grid>
          );
        })}
      </CardContent>
      <CardActions sx={{ justifyContent: "right", direction: "row" }}>
        <Stack direction={"row"} spacing={2}>
          <Button variant="text">Totali:</Button>
          <Button variant="text">Peso: {totRows.Peso} (kg)</Button>
          <Button variant="text">Qtà: {totRows.Quantita}</Button>
          <Button variant="text">Volume: {totRows.Volume} (mc)</Button>
          <Button variant="contained" color="primary" onClick={() => addDettaglioOrdine()} startIcon={<Add />}>
            Aggiungi
          </Button>
        </Stack>
      </CardActions>
    </Card>
  );
};

export default DettaglioMerce;
