import { AssignmentTwoTone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useState } from "react";
import React from "react";
import { OrdiniRitiroContext } from "../../../context";
import { useContext } from "react";
import { useEffect } from "react";
import { useForm } from "../../../hooks";
import { CurrencySimbol } from "../../../utilities";

const Contrassegno = ({ setMainForm, mainForm, formAutomatico, scrollBott, subtitle}) => {
  const [checked, setChecked] = useState(mainForm.sceltaContrassegno);

  const {
    state: { valuteList, tipiIncassi },
    getTipiIncassi,
    getValute,
  } = useContext(OrdiniRitiroContext);

  useEffect(() => {
    getTipiIncassi();
    getValute();

    if (contrassegnoForm.Contrassegno !== "") {
      setChecked(true)      
    }
  }, []);

  const {
    values: contrassegnoForm,
    changeHandler,
    setValues,
  } = useForm(
    {
      TipoIncasso: formAutomatico.TipoIncassoCOD || "",
      Contrassegno: formAutomatico.ImportoCOD || "",
      ContrassegnoValuta: formAutomatico.ValutaCOD || "EUR",      
    },
    []
  );


  useEffect(() => {
    if (!checked && formAutomatico.tipoIncasso ==="") {
      setValues({
        ...contrassegnoForm,
        TipoIncasso: "",
        Contrassegno: "",
        ContrassegnoValuta: "EUR",
      });
    } 
    else {
      setValues({ ...contrassegnoForm, sceltaContrassegno: checked });
    }

  }, [checked]);

  

  useEffect(() => {
    setMainForm({
      ...mainForm,
      TipoIncasso: contrassegnoForm.TipoIncasso,
      Contrassegno: contrassegnoForm.Contrassegno,
      ContrassegnoValuta: contrassegnoForm.ContrassegnoValuta,
    });

  }, [contrassegnoForm]);

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.info.main + "20" }}
    >
      <CardHeader
        title={"Contrassegno"}
        subheader={checked? (subtitle !== "" ? (subtitle):("")):("")}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<AssignmentTwoTone />}
        action={
          <FormControlLabel
            control={
              <Switch checked={checked} onClick={() => setChecked(!checked)} />
            }
            label=""
          />
        }
      />

      {checked ? (
        <Grow in={checked} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
          <CardContent>
            <Grid item container spacing={1}>
              <Grid item xs={12} md={4} lg={4} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Tipo incasso</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={contrassegnoForm.TipoIncasso || ""}
                    onChange={(e) =>
                      changeHandler("TipoIncasso", e.target.value)
                    }
                    label="Tipo incasso"
                  >
                    <MenuItem value={" "}>Seleziona tipo incasso</MenuItem>
                    {tipiIncassi?.map((params, key) => (
                      <MenuItem key={key} value={params.codice}>
                        {params.codice} - {params.descrizione}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4} mt={2}>
                <FormControl fullWidth>
                  <InputLabel id="select-from-nations">Valuta</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-from-nations"
                    id="select-from-nations"
                    value={contrassegnoForm.ContrassegnoValuta || ""}
                    onChange={(e) => changeHandler("ContrassegnoValuta", e.target.value)}
                    label="Valuta"
                  >
                    <MenuItem value={" "}>Seleziona valuta</MenuItem>
                    {valuteList?.map((params, key) => (
                      <MenuItem key={key} value={params.codice}>
                        {params.codice} - {params.descrizione}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <TextField
                  margin="normal"
                  fullWidth
                  size="small"
                  label="Importo"
                  autoComplete="off"
                  value={contrassegnoForm.Contrassegno || ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {CurrencySimbol(contrassegnoForm.ContrassegnoValuta)}
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => changeHandler("Contrassegno", e.target.value)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Grow>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default Contrassegno;
