import axios from "axios";
import { errorInterceptor, setLoading } from "./apiUtility";

const instance = axios.create({
  //timeout: 1000,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
    'Accept': 'application/json',
    'Accept-Language':localStorage.getItem('languages') 
    //"Access-Control-Allow-Origin":'*',
  },
});

const requestHandler = (request) => {
  const token = localStorage.getItem("token");
  if (token) {
    request.headers["Authorization"] = `Bearer ${token}`;
  }
  return request;
};


instance.interceptors.request.use((request) => {
  setLoading(true);
  requestHandler(request)
  return request;
});

instance.interceptors.response.use(
  (response) => {
    if(response.headers.authorization){
      let bearer =  response.headers.authorization
      let newToken= bearer?.split(" ")
      localStorage.removeItem("token");
      localStorage.setItem("token", newToken[1]);
    }
    setLoading(false);
    return response;
  },
  (error) => {
    errorInterceptor(error, 'Error');
    setLoading(false);
    return Promise.reject(error);
  }
);

export default instance;
