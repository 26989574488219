import * as React from "react";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import { LocalShipping, LocationOn, Search } from "@mui/icons-material";
import { useState } from "react";
import { ConfigClient } from "../utilities";
import { useEffect } from "react";
import { useWindowDimensions } from "../hooks";

const actionsList = [
  {
    icon: <LocalShipping />,
    name: "Spedisci un pacco",
    route: "/spedizioni",
    visible: false,
    idMenu: "tracking",
  },
  {
    icon: <LocationOn />,
    name: "Traccia una spedizione",
    route: "/tracking",
    visible: false,
    idMenu: "spedizioni",
  },
];

export default function BasicSpeedDial() {
  let navigate = useNavigate();
  let dimension = useWindowDimensions();

  const [actions, setActions] = useState(actionsList);

  useEffect(() => {
    let setMenu = ConfigClient();
    let azioni = actionsList;
    setMenu.forEach((element) => {
      if (element.idMenu === "spedizioni") {
        azioni[0].visible = true;
      } else if (element.idMenu === "tracking") {
        azioni[1].visible = true;
      }
      setActions(azioni);
    });
  }, []);

  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      icon={
        <SpeedDialIcon
          openIcon={<ArrowForwardIcon size={"small"} />}
          icon={<LocalShipping size={"small"} />}
        />
      }
      direction={dimension ? "right" : "left"}
      FabProps={{
        size: "small",
      }}
    >
      {actions
        .filter((res) => res.visible)
        .map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => navigate(action.route)}
          />
        ))}
    </SpeedDial>
  );
}
