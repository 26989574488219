import { NotificationContent } from "../components";
import { sendNotification } from "../hooks/useNotification";

export const setLoading = (loading) => window.dispatchEvent(new CustomEvent('change_loading', { detail: loading }));


export const errorInterceptor = (error, serviceName) => {
  //console.log('serviceName: ', serviceName);
  //console.log('error: ', error);
  if (error.response.data.message === "Token expired" || error.response.data.message ==="Authorization Required" || error.response.data.message ==="Invalid Token") {
    localStorage.removeItem('token')
    localStorage.removeItem('UserDescrizione')
    localStorage.removeItem('configurazione')
    window.location.assign("/");
    sendNotification(
      <NotificationContent service={serviceName} />,
      error.response.data.message ? error.response.data.message : error.message,
      "error",
      20
    );
  }else {
    sendNotification(
      <NotificationContent service={serviceName} />,
      error.response.data.message ? error.response.data.message : error.message,
      "error",
      5
    );
  }

};