import { NoteAdd } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField,
} from "@mui/material";
import React from "react";
import { useForm } from "../../../hooks";
import { useEffect } from "react";

const NotaDimensioneMerce = ({ mainForm, setMainForm  }) => {

  const { values: notaDimesioniMerceForm, changeHandler } = useForm(
    {
      NotaDimensioniMerce: "",
    },
    []
  );

  
  useEffect(() => {
    setMainForm({
      ...mainForm,
      NotaDimensioniMerce: notaDimesioniMerceForm.NotaDimensioniMerce,
    });
  }, [notaDimesioniMerceForm]);

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.warning.main + '20' }}
    >
        <CardHeader
          title={"Nota dimensioni merce"}
          subheader={""}
          subheaderTypographyProps={{
            fontWeight: "bold",
          }}
          titleTypographyProps={{
            fontWeight: "bold",
            fontSize:18
          }}
          avatar={<NoteAdd />}
        />
        <CardContent>
          <Grid item container spacing={1}>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                margin="normal"
                fullWidth
                multiline
                size="small"
                label="Nota dimensioni merce"
                autoComplete="off"
                defaultValue={notaDimesioniMerceForm.NotaDimensioniMerce || ""}
                onChange={(e) =>
                  changeHandler("NotaDimensioniMerce", e.target.value)
                }
              />
            </Grid>
          </Grid>
        </CardContent>
    </Card>
  );
};

export default NotaDimensioneMerce;
