import {
    Alert,
    AlertTitle,
    Grid,
    Snackbar,
    Typography,
    Card,
    CardHeader,
    CardContent,
    Stack,
    Button
  } from "@mui/material";
  import {AddBoxTwoTone} from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import { TrackingContext, PreventiviNewContext } from "../context";
import React, { useContext, useState } from 'react';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { RiepilogoPreventivo, PreventivoSingolo} from "../components/PreventiviFormComponent/"
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import EmailIcon from '@mui/icons-material/Email';
import { titlePage } from "../utilities";


const DettaglioPreventivo = () => {
    const [locationMap, setLocationMap] = useState(false);
    const navigate = useNavigate();
    
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        setLocationMap(false);
      };

    const{
        state:{
            form,
            result,
            contaValidi,
            completeResult
        },
        postEmailPreventivo,
    } = useContext(PreventiviNewContext);



    const {
      compilaSpedizioneAuto
    } = useContext(TrackingContext);



    const handleInvioMailPreventivo = () => {
      postEmailPreventivo(completeResult.PrgPreventivo);
    }

    function fillTotaleMerce(){
      let totali = {
        colli:{
          Quantita:0,
          Peso:0,
          Volume:0
        },
        palette:{
          Quantita:0,
          Peso:0,
          Volume:0
        }
      }


      let mercelist = form.ordmerce
      
      if (mercelist !== undefined){      
        mercelist.forEach((value, index, array) => {
          if (value.Tipo === 'C'){

            if(!(isNaN(value.Quantita))){
              totali.colli.Quantita = totali.colli.Quantita + parseInt(value.Quantita );
            }
            if(!(isNaN(value.Peso))){
              totali.colli.Peso = totali.colli.Peso + parseInt(value.Peso );
            }

            let volumeTemp = (parseInt(value.Quantita) * parseInt(value.Larghezza) * parseInt(value.Lunghezza) * parseInt(value.Altezza)) / 1000000;

            if(!(isNaN(volumeTemp))){
              totali.colli.Volume = totali.colli.Volume + volumeTemp;
            }
          }
          else if (value.Tipo === 'P'){
            if(!(isNaN(value.Quantita))){
              totali.palette.Quantita = totali.palette.Quantita + parseInt(value.Quantita )
            }

            if(!(isNaN(value.Peso))){
              totali.palette.Peso = totali.palette.Peso + parseInt(value.Peso );
            }

            let volumeTemp = (parseInt(value.Quantita) * parseInt(value.Larghezza) * parseInt(value.Lunghezza) * parseInt(value.Altezza)) / 1000000;
            if(!(isNaN(volumeTemp))){
              totali.palette.Volume = totali.palette.Volume + (parseInt(value.quantita) * parseInt(value.larghezza) * parseInt(value.lunghezza) * parseInt(value.altezza))/ 1000000
            }
          }
        });


        let ret =`` 

        if (totali.colli.Quantita > 0){
          ret = ret + ` ${totali.colli.Quantita} colli  ${totali.colli.Peso} kg`
          if(!isNaN(totali.colli.Volume) ){
            ret = ret + `  ${totali.colli.Volume} mc <br>`
          }
        }

        if (totali.palette.Quantita > 0){
          ret = ret + ` ${totali.palette.Quantita} palette  ${totali.palette.Peso} kg`;
          if(!isNaN(totali.palette.Volume) ){
            ret = ret + `  ${totali.palette.Volume} mc <br>`
          }
        }

        return (
          ret
        );
      }
      else {
        return (<></>)
      }
    }

    const handleModifica = () => {
      navigate(-1);
    };

    const handleCreaSpedzioneAuto = (servizio) => {
      compilaSpedizioneAuto(form , servizio, navigate)
    };
    

    useEffect(() => {
      //TODO gestire traduzioni titolo pagina
      titlePage("","Preventivi")
      if (form.ordmerce === undefined){
        navigate(-1)
      }
    }, [])

  return (
    <>
    <Stack direction={"row-reverse"} spacing={2} sx={{mb:2 ,mr:2}}>
      <Button
        color="warning"
        size={"medium"}
        variant={"contained"}
        startIcon={<EditIcon />}
        onClick={() => handleModifica()}
      >
        Modifica
      </Button>


      <Button 
        size="medium"
        variant='contained'
        startIcon={<EmailIcon/> }
        style={{justifyContent: 'center'}}
        onClick={handleInvioMailPreventivo}
      >
        Invia email
      </Button>

      <Button 
        size="medium"
        variant='contained'
        startIcon={<AddBoxTwoTone/> }
        style={{justifyContent: 'center'}}
        onClick={handleCreaSpedzioneAuto}
      >
        Crea spedizione
      </Button>


    </Stack>

    <Grid>
      <Grid container spacing={0} key={uuidv4()}>
        <Snackbar
          open={locationMap}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            Coordinate non presenti
          </Alert>
        </Snackbar>
        {form?.MittLocalita !== "" ? (
          <Grid item xs={12} md={6} lg={6} key={uuidv4()}>
            <Alert severity="info" icon={<></>} sx={{ background: (theme) => theme.palette.error.light + "25" }}>
              <AlertTitle><strong>Mittente</strong></AlertTitle>
              {
                <ReactMarkdown
                  children={form ? `${form?.MittCap}  ${form?.MittLocalita}  ${form?.MittProvincia}  ${form?.MittNazioneCompleta}` : ""}
                  rehypePlugins={[rehypeRaw]}
                ></ReactMarkdown>
              }{" "}
            </Alert>
          </Grid>
        ) : (
          <></>
        )}
        {/* <ArrowForwardIosIcon/> */}
        {form?.DestLocalita !== "" ? (
            <Grid item xs={12} md={6} lg={6} key={uuidv4()} >
              <Alert severity="success" icon={<></>}>
              <AlertTitle><strong>Destinatario</strong></AlertTitle>
                {
                  <ReactMarkdown
                    children={form ? `${form?.DestCap}  ${form?.DestLocalita}  ${form?.DestProvincia}  ${form?.DestNazioneCompleta}` : ""}
                    rehypePlugins={[rehypeRaw]}
                  ></ReactMarkdown>
                }{" "}
              </Alert>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} md={12} lg={12} key={uuidv4()} >
          <Card 
            elevation={1}
            sx={{ background: (theme) => theme.palette.info.main + "20" }} 
          >
            <CardHeader
              title={"Totale merce"}
              subheader={""}
              subheaderTypographyProps={{
                fontWeight: "bold",
              }}
              titleTypographyProps={{
                fontWeight: "bold",
                fontSize: 18,
              }}
            />
            <CardContent>            
              <ReactMarkdown
                children={form ? fillTotaleMerce() : ""}
                rehypePlugins={[rehypeRaw]}
              ></ReactMarkdown>
            
            </CardContent>
          </Card>
        </Grid>

      </Grid>
      <RiepilogoPreventivo  />
    </Grid>

    {/* <Container>     */}

    {
      contaValidi > 0?
        (
          result.map((element) => {
            if (element.Importo !== ""){
              return(
                <PreventivoSingolo DatiPreventivo={element} creaSpedizioneAuto={handleCreaSpedzioneAuto} />
              )     
            }     
            return(<></>);  
          })      
        )
        :
        (
          <Alert severity="warning" icon={<></>} sx={{ display: "flex", justifyContent: "center" }}>
            <Typography variant="h4"   sx={{ display: "flex", justifyContent: "center" }}>
              Nessuna tariffa compatibile con i dati inseriti.
            </Typography>
          </Alert>
        )
    }   
    {/* </Container>       */}

    <Stack direction={"row-reverse"} spacing={2} sx={{mt:2 ,mr:2}}>
      <Button
        color="warning"
        size={"medium"}
        variant={"contained"}
        startIcon={<EditIcon />}
        onClick={() => handleModifica()}
      >
        Modifica
      </Button>
    </Stack>

      </>
  )
}

export default DettaglioPreventivo