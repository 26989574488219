import { Add, Delete, WarningTwoTone } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useForm } from "../../../hooks";
import { OrdiniRitiroContext } from "../../../context";

const MerceADR = ({ scrollOnBott, mainForm, setMainForm }) => {
  const [checked, setChecked] = useState(mainForm.sceltaADR);

  const {
    state: { adrClassi, adrImballaggi },
    getClassi,
    getImballaggi,
  } = useContext(OrdiniRitiroContext);

  useEffect(() => {
    getClassi();
    getImballaggi();
  }, []);

  const [merceAdrForm, setMerceAdrForm] = useState([
    {
      id: uuidv4(),
      Classe: "-1",
      Imballaggio: "-1",
      NumONU: "",
      Descrizione: "",
      Colli: "",
      Peso: "",
    },
  ]);

  const {
    values: merceADRForm,
    changeHandler,
    setValues
  } = useForm(
    {
      ordadr: mainForm.ordadr,
    },
    []
    );

  useEffect(() => {
    if (!checked) {
      changeHandler("ordadr", mainForm.ordadr);
    }
    //scrollOnBott(checked);
    setValues({ ...merceADRForm, sceltaADR: checked });
  }, [checked]);

  useEffect(() => {
    if (!checked) {
      changeHandler("ordadr", mainForm.ordadr);
    }
    //scrollOnBott(checked);
    setValues({ ...merceADRForm, sceltaADR: checked });
  }, [checked]);

  useEffect(() => {
    if (merceADRForm.ordadr.length > 0) {
      setMerceAdrForm(merceADRForm.ordadr);
    }
  }, []);

  useEffect(() => {
    setMainForm({ ...mainForm, ordadr: merceADRForm.ordadr});
  }, [merceADRForm]);


  const addMerceAdr = () => {
    setMerceAdrForm([
      ...merceAdrForm,
      {
        id: uuidv4(),
        Classe: "-1",
        Imballaggio: "-1",
        NumONU: "",
        Descrizione: "",
        Colli: "",
        Peso: "",
      },
    ]);
  };

  const cancellaInserimento = (id) => {
    setMerceAdrForm([...merceAdrForm.filter((res) => res.id !== id)]);
  };

  const handelChangeValue = (key, value, index, row) => {
    let arrayData = [...merceAdrForm.filter((res) => res.id !== row.id)];
    arrayData.splice(index, 0, { ...merceAdrForm[index], [key]: value });
    setMerceAdrForm(arrayData);
    changeHandler("ordadr", arrayData);
  };

  return (
    <Card
      elevation={0}
      sx={{ background: (theme) => theme.palette.info.main + "20" }}
    >
      <CardHeader
        title={"Merce ADR"}
        subheader={""}
        subheaderTypographyProps={{
          fontWeight: "bold",
        }}
        titleTypographyProps={{
          fontWeight: "bold",
          fontSize: 18,
        }}
        avatar={<WarningTwoTone />}
        action={
          <Stack direction={"row"} spacing={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={checked}
                  onClick={() => setChecked(!checked)}
                />
              }
              label=""
            />
          </Stack>
        }
      />
      {checked ? (
        <Grow in={checked} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
          <CardContent>
            {merceAdrForm.map((res, i) => {
              return (
                <Grid item container spacing={1} key={i}>
                  <Grid item xs={12} md={1} lg={1} mr={-5} mt={2}>
                    <IconButton
                      onClick={() => cancellaInserimento(res.id)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} mt={2}>
                    <FormControl fullWidth>
                      <InputLabel id="select-from-nations">Classe</InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        labelId="select-from-nations"
                        id="select-from-nations"
                        value={res.Classe || ""}
                        defaultValue={-1}
                        onChange={(e) =>
                          handelChangeValue("Classe", e.target.value, i, res)
                        }
                        label="Classe"
                      >
                        <MenuItem value={"-1"}>Seleziona classe</MenuItem>
                        {adrClassi.map((data, index) => {
                          return (
                            <MenuItem value={data.codice} key={index}>
                              {data.descrizione}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} mt={2}>
                    <FormControl fullWidth>
                      <InputLabel id="select-from-nations">
                        Imballaggi
                      </InputLabel>
                      <Select
                        fullWidth
                        size="small"
                        labelId="select-from-nations"
                        id="select-from-nations"
                        value={res.Imballaggio || ""}
                        onChange={(e) =>
                          handelChangeValue(
                            "Imballaggio",
                            e.target.value,
                            i,
                            res
                          )
                        }
                        label="Imballaggi"
                      >
                        <MenuItem value={"-1"}>Seleziona imballaggi</MenuItem>
                        {adrImballaggi.map((data, index) => {
                          return (
                            <MenuItem value={data.codice} key={index}>
                              {data.descrizione}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={1} lg={1}>
                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      label="n° ONU"
                      autoComplete="off"
                      value={res.NumONU || ""}
                      onChange={(e) =>
                        handelChangeValue("NumONU", e.target.value, i, res)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3} lg={3}>
                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      label="Descrizione"
                      autoComplete="off"
                      value={res.Descrizione || ""}
                      onChange={(e) =>
                        handelChangeValue("Descrizione", e.target.value, i, res)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={1} lg={1}>
                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      label="Colli"
                      autoComplete="off"
                      value={res.Colli || ""}
                      onChange={(e) =>
                        handelChangeValue("Colli", e.target.value, i, res)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2} lg={2}>
                    <TextField
                      margin="normal"
                      fullWidth
                      size="small"
                      label="Peso"
                      autoComplete="off"
                      value={res.Peso || ""}
                      onChange={(e) =>
                        handelChangeValue("Peso", e.target.value, i, res)
                      }
                    />
                  </Grid>
                </Grid>
              );
            })}
            <CardActions sx={{ justifyContent: "right", direction: "row" }}>
              <Stack direction={"row"}>
                <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => addMerceAdr()}>
                  {" "}
                  Aggiungi
                </Button>
              </Stack>
            </CardActions>
          </CardContent>
        </Grow>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default MerceADR;
