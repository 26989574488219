import { AssignmentTwoTone } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, {useEffect, useState, useContext} from 'react'
import { PreventiviNewContext } from '../../context'
import {useForm} from "../../hooks"
import { CurrencySimbol } from "../../utilities";
import { v4 as uuidv4 } from "uuid";

const Contrassegno = ({prevMainForm, setPrevMainForm}) => {
    const [contrassegnoChecked, setContrassegnoChecked] = useState(false/*nuovoPreventivoForm.sceltaContrassegno*/);

    const {
        state: { valuteList, tipiIncassi },
        getTipiIncassi,
        getValute,
      } = useContext(PreventiviNewContext);

    const {
        values: contrassegnoForm,
        changeHandler,
        setValues,
      } = useForm(
        {
          TipoIncasso: " ",
          Contrassegno: "",
          ContrassegnoValuta: "EUR",
          IncassoCodice : "",
          IncassoDescrizione : "",
        },
        []
    );



    useEffect(() => {

      if (prevMainForm?.Contrassegno !== "" && prevMainForm.Contrassegno !== undefined) {
        setValues({
          ...contrassegnoForm,
          ContrassegnoValuta : prevMainForm?.ContrassegnoValuta,
          Contrassegno : prevMainForm?.Contrassegno,
          TipoIncasso : prevMainForm?.TipoIncasso
        })
        setContrassegnoChecked(true)
      }        
    }, [prevMainForm.ordadr]);

    function getDescrizionePerTipoIncasso(incassoCodice){
      let ret = "";
      tipiIncassi.map((row) =>{
        if(row.codice === incassoCodice){
          ret = row.descrizione;
        };
      });
      return ret;
    }
   
    useEffect(() => {
        getTipiIncassi();
        getValute();
      }, []);

    useEffect(() => {
        if (!contrassegnoChecked) {
            setValues({
                ...contrassegnoForm,
                TipoIncasso: " ",
                Contrassegno: "",
                ContrassegnoValuta: "EUR",
                IncassoDescrizione : ""
              });
            }
            setValues({ ...contrassegnoForm, sceltaContrassegno: contrassegnoChecked });
        
          }, [contrassegnoChecked]);
      
    useEffect(() => {
        setPrevMainForm({ ...prevMainForm, 
              TipoIncasso: contrassegnoForm.TipoIncasso,
              Contrassegno: contrassegnoForm.Contrassegno,
              ContrassegnoValuta: contrassegnoForm.ContrassegnoValuta,
              IncassoDescrizione: getDescrizionePerTipoIncasso(contrassegnoForm.TipoIncasso),
            });
        }, [contrassegnoForm]);

  return (
<   Card
            elevation={0}
            sx={{ background: (theme) => theme.palette.info.main + "20" }}
          >
            <CardHeader
              title={"Contrassegno"}
              subheader={""}
              subheaderTypographyProps={{
                fontWeight: "bold",
              }}
              titleTypographyProps={{
                fontWeight: "bold",
                fontSize: 18,
              }}
              avatar={<AssignmentTwoTone />}
              action={
                <FormControlLabel
                  control={
                    <Switch checked={contrassegnoChecked} onClick={() => setContrassegnoChecked(!contrassegnoChecked)} />
                  }
                  label=""
                />
              }
            />

            {contrassegnoChecked ? (
              <Grow in={contrassegnoChecked} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
                <CardContent>
                  <Grid item container spacing={1}>
                    <Grid item xs={12} md={4} lg={4} mt={2}>
                      <FormControl fullWidth>
                        <InputLabel id="select-from-nations">Tipo incasso</InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          labelId="select-from-nations"
                          id="select-from-nations"
                          value={prevMainForm.TipoIncasso || ""}
                          onChange={(e) => {
                            changeHandler("TipoIncasso", e.target.value)

                          }
                          }
                          label="Tipo incasso"
                        >
                          <MenuItem value={" "} key={uuidv4()}>Seleziona tipo incasso</MenuItem>
                          {tipiIncassi?.map((params, key) => (
                            <MenuItem key={key} value={params.codice}>
                              {params.codice} - {params.descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} mt={2}>
                      <FormControl fullWidth>
                        <InputLabel id="select-from-nations">Valuta</InputLabel>
                        <Select
                          fullWidth
                          size="small"
                          labelId="select-from-nations"
                          id="select-from-nations"
                          value={prevMainForm.ContrassegnoValuta || ""}
                          onChange={(e) => changeHandler("ContrassegnoValuta", e.target.value)}
                          label="Valuta"
                        >
                          <MenuItem key={uuidv4()} value={" "}>Seleziona valuta</MenuItem>
                          {valuteList?.map((params, key) => (
                            <MenuItem key={key} value={params.codice}>
                              {params.codice} - {params.descrizione}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                      <TextField
                        margin="normal"
                        fullWidth
                        size="small"
                        label="Importo"
                        autoComplete="off"
                        value={prevMainForm.Contrassegno || ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {CurrencySimbol(prevMainForm.ContrassegnoValuta)}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => changeHandler("Contrassegno", e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grow>
            ) : (
              <></>
            )}
          </Card>  )
}

export default Contrassegno