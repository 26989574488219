import { Policy } from "@mui/icons-material";
import {
    Card,
    CardContent,
    CardHeader,
    FormControl,
    FormControlLabel,
    Grid,
    Grow,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { PreventiviNewContext } from "../../context";
import { useForm } from "../../hooks";
import { CurrencySimbol } from "../../utilities";


const Assicurazione = ({prevMainForm, setPrevMainForm}) => {
    const [assicurazioneChecked, setAssicurazioneChecked] = useState(false);
    const {
        state: { valuteList },
        getValute,
      } = useContext(PreventiviNewContext);

    useEffect(() => {
        getValute();
    }, []);

    const {
        values: assicurazioneForm,
        changeHandler,
        setValues,
      } = useForm(
        {
          StimaValMerce: "",
          StimaValMerceValuta: "EUR",
        },
        []
      );

      useEffect(() => {
        if (!assicurazioneChecked) {
          setValues({
            ...assicurazioneForm,
            StimaValMerce: "",
            StimaValMerceValuta: "EUR",
          });
        }
        //testCheck(checked)
        setValues({ ...assicurazioneForm, sceltaAssicurazione: assicurazioneChecked });
      }, [assicurazioneChecked]);

      useEffect(() => {
        setPrevMainForm({
          ...prevMainForm,
          StimaValMerce: assicurazioneForm.StimaValMerce,
          StimaValMerceValuta: assicurazioneForm.StimaValMerceValuta,
        });
      }, [assicurazioneForm]);

    return (
        <Card
              elevation={0}
              sx={{ background: (theme) => theme.palette.info.main + "20" }}
            >
              <CardHeader
                title={"Assicurazione"}
                subheader={""}
                subheaderTypographyProps={{
                  fontWeight: "bold",
                }}
                titleTypographyProps={{
                  fontWeight: "bold",
                  fontSize: 18,
                }}
                avatar={<Policy />}
                action={
                  <FormControlLabel
                    control={
                      <Switch checked={assicurazioneChecked} onClick={() => setAssicurazioneChecked(!assicurazioneChecked)} />
                    }
                    label=""
                  />
                }
              />
              {assicurazioneChecked ? (
                <Grow in={assicurazioneChecked} timeout={{ appear: 1000, enter: 1000, exit: 1000 }}>
                  <CardContent>
                    <Grid item container spacing={1}>
                      <Grid item xs={12} md={6} lg={6} mt={2}>
                        <FormControl fullWidth>
                          <InputLabel id="select-from-nations">Valuta</InputLabel>
                          <Select
                            fullWidth
                            size="small"
                            labelId="select-from-nations"
                            id="select-from-nations"
                            value={assicurazioneForm.StimaValMerceValuta || ""}
                            onChange={(e) =>
                              changeHandler("StimaValMerceValuta", e.target.value)
                            }
                            label="Valuta"
                          >
                            <MenuItem value={""}>Seleziona valuta</MenuItem>
                            {valuteList?.map((params, key) => (
                              <MenuItem key={key} value={params.codice}>
                                {params.codice} - {params.descrizione}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <TextField
                          margin="normal"
                          fullWidth
                          size="small"
                          label="Valore merce"
                          autoComplete="off"
                          value={assicurazioneForm.StimaValMerce || ""}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {CurrencySimbol(assicurazioneForm.StimaValMerce)}
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            changeHandler("StimaValMerce", e.target.value)
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Grow>
              ) : (
                <></>
              )}
            </Card>
    );
};

export default Assicurazione;
