import createDataContext from "./createContext";

const UPLOAD ="upload"

const INITIAL_STATE = {
  file:""
};


const fileUploadReducer = (state, action) => {
  switch (action.type) {
    case UPLOAD:{
        const {file} = action.payload
        return{...state, file}
    }
    default: {
      return state;
    }
  }
};

const fileUpload = (dispatch) => async (newfile) => {  
    dispatch({ type: UPLOAD, payload: newfile })
};




export const { Provider, Context } = createDataContext(
    fileUploadReducer,
  {
    fileUpload
  },
  INITIAL_STATE 
);