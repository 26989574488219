import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Grow,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Skeleton,
  Zoom,
} from "@mui/material";
import { AirlineStopsTwoTone } from "@mui/icons-material";
import { DashboardContext } from "../../context";
import { useContext } from "react";
import { iconStateTracking } from "../../utilities";
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ScaleIcon from '@mui/icons-material/Scale';
import ViewInArIcon from '@mui/icons-material/ViewInAr';

export const SpedizioniMese = ({ mesecorrente, meseprecedente, spedGiorno }) => {
  const {
    state: { loadSpedizioni },
  } = useContext(DashboardContext);
  
  return (
    <Card sx={{ height: "100%" }} elevation={loadSpedizioni ? 3 : 1}>
      <CardHeader
        title={
          loadSpedizioni ? (
            `Spedizioni`
          ) : (
            <Skeleton animation="wave" height={40} width="50%" />
          )
        }
        action={
          loadSpedizioni ? (
            <Zoom
              in={loadSpedizioni}
              timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
            >
              <Avatar
                sx={{
                  backgroundColor: "primary.main",
                  height: 56,
                  width: 56,
                }}
              >
                <AirlineStopsTwoTone />
              </Avatar>
            </Zoom>
          ) : (
            <Skeleton
              animation="wave"
              variant="circular"
              width={56}
              height={56}
            />
          )
        }
      />
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          {loadSpedizioni ? (
            <Grow
              in={loadSpedizioni}
              timeout={{ appear: 1000, enter: 1000, exit: 1000 }}
            >
              <Grid container>

                <Grid item xs={12} md={12} lg={12}>
                  <Divider textAlign="center">
                    OGGI
                  </Divider>
                  
                  <List
                    style={{
                      display:'flex',
                      flexDirection:'row'
                    }}
                  >
                    <Grid container >
                      <Grid item>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <Grid3x3Icon/>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Bancali"
                            secondary={spedGiorno?.TotalePalette}
                          />
                        </ListItem>                  
                      </Grid>
                      <Grid item>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <Inventory2Icon/>
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary="Colli"
                            secondary={spedGiorno?.TotaleColli}
                          />
                        </ListItem>
                      </Grid>

                      <Grid item>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <ScaleIcon/>
                            </Avatar>
                          </ListItemAvatar>
                            <ListItemText
                              primary="Peso"
                              secondary={spedGiorno?.TotalePeso}
                            />
                        </ListItem>
                      </Grid>

                      <Grid item>
                        <ListItem>
                          <ListItemAvatar>
                            <Avatar>
                              <ViewInArIcon/>
                            </Avatar>
                          </ListItemAvatar>
                            <ListItemText
                              primary="Volume"
                              secondary={spedGiorno?.TotaleVolume}
                            />
                        </ListItem>
                      </Grid>
                    </Grid>
                  </List>                      
                </Grid>                
                <Grid item xs={12} md={6} lg={6}>
                  <Divider textAlign="left">
                    {mesecorrente.in_consegna?.Month.toUpperCase()}
                  </Divider>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                    }}
                    subheader={<ListSubheader></ListSubheader>}
                  >

                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("INC", "In consegna")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="In consegna"
                        secondary={mesecorrente.in_consegna?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("CON", "Consegnate")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Consegnate"
                        secondary={mesecorrente.consegnate?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{iconStateTracking("GIA", "Giacenti")}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Giacenti"
                        secondary={mesecorrente.giacente?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("TRAN", "In transito")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="In transito"
                        secondary={mesecorrente.in_transito?.Value}
                      />
                    </ListItem>
                    
                  </List>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Divider textAlign="left">
                    {meseprecedente.in_consegna?.Month.toUpperCase()}
                  </Divider>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                    }}
                    subheader={<ListSubheader></ListSubheader>}
                  >
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("INC", "In consegna")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="In consegna"
                        secondary={meseprecedente.in_consegna?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("CON", "Consegnate")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Consegnate"
                        secondary={meseprecedente.consegnate?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{iconStateTracking("GIA", "Giacenti")}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="Giacenti"
                        secondary={meseprecedente.giacente?.Value}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          {iconStateTracking("TRAN", "In transito")}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="In transito"
                        secondary={meseprecedente.in_transito?.Value}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grow>
          ) : (
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Skeleton
                        animation="wave"
                        variant="circular"
                        width={40}
                        height={40}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"20%"}
                          height={12}
                        />
                      }
                      secondary={
                        <Skeleton
                          animation="wave"
                          variant="rectangular"
                          width={"10%"}
                          height={5}
                          sx={{ marginTop: 1 }}
                        />
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
